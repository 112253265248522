import { useEffect, useMemo, useState } from 'react';
import { Delete, Return, ToCRM, Exclude, BlockAuthor } from '../../../composed-components/Actions';
import { useBooksList, useDataRowCount } from '../../../core/modules/book/hooks';
import { SOURCE, WATCHLIST, WATCHLIST_RR } from '../../../core/constants';
import ResizeTable from '../../../composed-components/Resize/Resize';
import { BASEFONTSIZE, COLUMNSHEADING, PAGESIZE, REFETCHDELAY, RefetchOptions } from '../constants';
import { EFontSize, TColumns } from '../types/types';
import { Sorting, FilterForm, Settings, SpinnerTabs } from '../../../composed-components';
import { useFilter } from '../../../context/Filter.context';
import { useSort } from '../../../context/Sort.context';
import ToPodiumTitle from '../../../composed-components/Actions/ToPodiumTitle';
import Pagination from '../../../components/Pagination';
import getAllColumns from '../columns/getAllColumns';
import { useUserPermit } from '../../../context/User.context';
import { TableFooter } from '../../../components/Table';
import Legend from '../../../composed-components/Legend';
import { useActions } from '../../../context/Actions.context';
import getDataSource from '../utils/getDataSource';
import { applyRoleBasedFilters } from '../utils/roleBasedFilters';

const Watchlist = () => {
  const { edit_titles, move_titles_hubspot, role, default_filters: userDefaultFilters } = useUserPermit();
  const [fontSize, setFontSize] = useState<keyof typeof EFontSize>(BASEFONTSIZE);
  const [page, setPage] = useState<number>(1);
  const [deletedItems, setDeletedItems] = useState<string[]>([]);
  const [selectedOption, setSelectedOption] = useState<string>('All Titles');
  const [isFilterInitialized, setIsFilterInitialized] = useState<boolean>(false);

  const { source: sourcePath, isActionLoading, setActionLoading, isRefreshed } = useActions();
  const { filter, setFilter } = useFilter();
  const { sort } = useSort();

  const deleteBooks = book => setDeletedItems(book);
  const changeFontSize = size => setFontSize(size);
  const changePage = p => setPage(p);

  const isBookstat = sourcePath['source'].includes(SOURCE.bookstat);
  const TAB_ID = isBookstat ? WATCHLIST : WATCHLIST_RR;

  const { data = [], isLoading, isFetching, refetch } = useBooksList(
    TAB_ID, {
      page,
      ...filter[TAB_ID],
      ...sort[TAB_ID]
    });

  const { count, refetch: refetchRowCount } = useDataRowCount(TAB_ID, { ...filter[TAB_ID] });

  const refetchTable = () => {
    setTimeout(() => {
      refetch();
      refetchRowCount();
      setActionLoading(false);
    }, REFETCHDELAY);
  };

  const sortColumns = (title: string) => (
    <Sorting id={TAB_ID} title={title} changePage={changePage} />
  );

  const thead = useMemo(() => {
    const allColumns = getAllColumns(sortColumns);
    const head = COLUMNSHEADING[TAB_ID].map(title => allColumns[title]);

    if (edit_titles) {
      head.push({
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        className: 'show td-actions',
        fixed: 'right',
        width: 90,
        render: (_, book) => (
          <div className={'actions'}>
            <Delete book={book} id={TAB_ID} />
            <BlockAuthor book={book} id={TAB_ID} />
            <Exclude book={book} id={TAB_ID} />
            <ToPodiumTitle book={book} refetchTable={refetchTable} id={TAB_ID} />
            {move_titles_hubspot && <ToCRM book={book} id={TAB_ID} />}
            <Return book={book} id={TAB_ID} />
          </div>
        )
      });
    }

    return head;
  }, [edit_titles, move_titles_hubspot, COLUMNSHEADING, sortColumns]);

  const [columns, setColumns] = useState<TColumns[]>(thead);
  const dataSource = getDataSource(data, selectedOption, isBookstat);

  useEffect(() => {
    if (!isFilterInitialized) {
      if (!window.location.search) {
        const updatedFilters = applyRoleBasedFilters(filter, role, userDefaultFilters);

        setFilter(prevFilters => ({
          ...prevFilters,
          ...updatedFilters
        }));
      }

      setIsFilterInitialized(true);
    }
  }, []);

  useEffect(() => {
    if (!isRefreshed && isFilterInitialized) {
      refetch(RefetchOptions);
      refetchRowCount(RefetchOptions);
    }

    return () => {
      setSelectedOption('All Titles');
    };
  }, [page, filter[TAB_ID], sort[TAB_ID], isFilterInitialized]);

  return (
    <>
      <div className={'wrapper-controller'}>
        <FilterForm
          id={TAB_ID}
          page={page}
          changePage={changePage}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
        <Settings
          thead={thead}
          refetchTable={refetchTable}
          id={TAB_ID}
          deleteBooks={deleteBooks}
          changeFontSize={changeFontSize}
          setColumns={setColumns}
          deleteItems={deletedItems}
        />
      </div>
      {!isLoading ? (
        <>
          <ResizeTable
            fontSize={fontSize}
            dataSource={dataSource}
            id={TAB_ID}
            deleteItems={deletedItems}
            isFetching={isFetching || isActionLoading}
            deleteBooks={deleteBooks}
            columns={columns}
            refetchTable={refetchTable}
          />
          <TableFooter>
            {isBookstat ?
              (<Legend map={['crm', 'podium', 'price']} />) :
              (<Legend map={['crm', 'podium']} />)}
            <Pagination
              total={count}
              current={page}
              onChange={setPage}
              pageSize={PAGESIZE}
            />
          </TableFooter>
        </>
      ) : (
        <SpinnerTabs isLoading={isLoading} />
      )}
    </>
  );
};

export default Watchlist;