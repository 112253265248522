import React, { useEffect } from 'react';
import debounce from 'debounce';
import { Form, Select, AutoComplete } from 'antd';
import { SearchOutlined, UndoOutlined, FireTwoTone, StarTwoTone } from '@ant-design/icons';
import Input from '../../components/Input';
import FilterItem from './FilterItem';
import setFirstPage from '../../section/Common/utils/setFirstPage';
import './styles.css';
import {
  ALL_NEW_TITLE,
  BOOKSTAT_DATA,
  PODIUM_SALES,
  WATCHLIST,
  ALL_TITLES_RR,
  POPULAR,
  WATCHLIST_RR,
  TRENDING,
  RISING_STARS,
  ALL_EXCLUDED_RR,
  PODIUM_TITLES_RR,
  SENT_TO_CRM_RR,
  AUTHORS,
  DAILY_RANK_EBOOKS,
  DAILY_RANK_AUDIO_BOOKS,
  DAILY_RANK_CATALOGUE,
  PENDING_EXCLUSION,
  PENDING_EXCLUSION_RR,
  PODIUMAUTHORS
} from '../../core/constants';
import FilterItemTag from './FilterItemTag';
import styled from 'styled-components';
import JobStatus from '../JobStatus/JobStatus';
import SaveSearchButton from '../SavedSearches/SaveSearchButton';
import { useFilter } from '../../context/Filter.context';
import { defaultSort, useSort } from '../../context/Sort.context';
import { useActions } from '../../context/Actions.context';
import { useHistory } from 'react-router-dom';
import { useBooksList, useDataRowCount } from '../../core/modules/book/hooks';

const { Option } = Select;

const StyledReset = styled.button`
  border: none;
  outline: none;
  background: none;
  color: #9c9a9a;
  font-weight: 600;
  margin: 0;
  cursor: pointer;
  svg {
    width: 26px;
    height: 26px;
    transform: rotate(45deg);
  }
  &:hover {
    color: #00b27b;
  }
`;

const selectProps = {
  mode: 'multiple' as const,
  className: 'filter-select',
  showArrow: true,
  allowClear: true
};

interface IProps {
  page: number;
  changePage: (newPage: number) => void;
  id: any;
  selectedOption?: string;
  setSelectedOption?: React.Dispatch<React.SetStateAction<any>>;
  dataRefreshTime?: string;
  isBackendJobComplete?: boolean;
}

const FilterForm = ({ page, changePage, id, selectedOption,
  setSelectedOption, dataRefreshTime, isBackendJobComplete }: IProps) => {
  const [form] = Form.useForm();
  const { filter, setFilter } = useFilter();
  const { sort, setSort } = useSort();
  const { source: sourcePath, setIsRefreshed } = useActions();
  const history = useHistory();
  const { refetch: refetchData } = useBooksList(id, { runBy: 'BrendaCaching' });
  const { refetch: refetchDataRowCount } = useDataRowCount(id, { runBy: 'BrendaCaching' });

  const RoyalRoadTabs = [
    ALL_TITLES_RR,
    POPULAR,
    WATCHLIST_RR,
    TRENDING,
    RISING_STARS,
    PENDING_EXCLUSION_RR,
    ALL_EXCLUDED_RR,
    PODIUM_TITLES_RR,
    SENT_TO_CRM_RR
  ];

  const DailyRankTabs = [DAILY_RANK_EBOOKS, DAILY_RANK_AUDIO_BOOKS, DAILY_RANK_CATALOGUE];

  const getFilterData = debounce((a, values: { [x: string]: any; }) => {
    const params = {};

    for (const key in values) {
      if (values[key] instanceof Array) {
        params[key.replace(id, '')] = values[key].map(
          (value: string) => value.replace(/\s{2,}/g, ' ').trim()
        );
      } else {
        params[key.replace(id, '')] = key === `${id}search` ?
          values[key].replace(/\s{2,}/g, ' ').trim() :
          values[key];
      }
    }
    setFilter({ ...filter, [id]: params });
    setFirstPage(page, changePage);
    window.gtag('event', 'click', { event_category: 'Interaction', event_label: `Filter: ${Object.keys(a)[0]}` });
  }, 2000);

  const onReset = async () => {
    setIsRefreshed(true);
    history.push({ search: '' });
    setFilter({
      ...filter,
      [id]: {
        amazonasin: [],
        author: [],
        genres: [],
        search: '',
        title: [],
        tag: [],
        not_tag: [],
        seriestitle: [],
        days_tracked: [],
        status_tag: [],
        type_tag: [],
        reputation: [],
        author_tags: [],
        author_name: [],
        fiction_tags: [],
        fiction_name: [],
        warning_tags: [],
        not_warning_tags: [],
        genre_categories: [],
        date_range: undefined,
        series: []
      }
    });
    setFirstPage(page, changePage);
    setTimeout(() => form.resetFields());
    setSort(defaultSort);
  };

  const initialValues = {
    [`${id}search`]: filter[id]?.search,
    [`${id}title`]: filter[id]?.title,
    [`${id}author`]: filter[id]?.author,
    [`${id}amazonasin`]: filter[id]?.amazonasin,
    [`${id}genres`]: filter[id]?.genres,
    [`${id}tag`]: filter[id]?.tag,
    [`${id}not_tag`]: filter[id]?.not_tag,
    [`${id}seriestitle`]: filter[id]?.seriestitle,
    [`${id}days_tracked`]: filter[id]?.days_tracked,
    [`${id}status_tag`]: filter[id]?.status_tag,
    [`${id}type_tag`]: filter[id]?.type_tag,
    [`${id}reputation`]: filter[id]?.reputation,
    [`${id}fiction_name`]: filter[id]?.fiction_name,
    [`${id}author_tags`]: filter[id]?.author_tags,
    [`${id}author_name`]: filter[id]?.author_name,
    [`${id}fiction_tags`]: filter[id]?.fiction_tags,
    [`${id}warning_tags`]: filter[id]?.warning_tags,
    [`${id}not_warning_tags`]: filter[id]?.not_warning_tags,
    [`${id}genre_categories`]: filter[id]?.genre_categories,
    [`${id}date_range`]: filter[id]?.date_range,
    [`${id}series`]: filter[id]?.series,
    [`${id}late_bloomer`]: filter[id]?.late_bloomer
  };

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  useEffect(() => {
    if (filter[id]?.option && setSelectedOption) {
      setSelectedOption(filter[id]?.option);
    }
  }, [filter[id]?.option]);

  const handleReset = async () => {
    await onReset();
    refetchDataRowCount();
    refetchData();
    setIsRefreshed(false);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Form
        className='filter'
        form={form}
        onKeyPress={e => {
          if (e.code === 'Enter') {
            e.preventDefault();
          }
        }}
        onValuesChange={getFilterData}
        initialValues={initialValues}
      >
        <div className='filter-control-select'>
          <Form.Item name={`${id}search`}>
            <Input className='filter-input' prefix={<SearchOutlined />} />
          </Form.Item>
          {(id !== AUTHORS && id !== PODIUMAUTHORS &&
            !DailyRankTabs.includes(id) &&
            !RoyalRoadTabs.includes(id)) &&
            (<><FilterItem
              placeholder='Titles'
              selectProps={selectProps}
              point='title'
              id={id}
            />
            <FilterItem
              selectProps={selectProps}
              placeholder='Authors'
              point='author'
              id={id}
            />
            <FilterItem
              selectProps={selectProps}
              point='amazonasin'
              placeholder='ASINs'
              id={id}
            />
            {id !== PODIUM_SALES && (
              <FilterItem
                selectProps={selectProps}
                point='genres'
                placeholder='Genres'
                id={id}
              />
            )}
            {(id !== PODIUM_SALES && id !== BOOKSTAT_DATA) &&
                (<FilterItemTag
                  selectProps={selectProps}
                  point='tag'
                  placeholder='Tags'
                  id={id}
                />
                )}
            <FilterItem
              id={id}
              selectProps={selectProps}
              point='seriestitle'
              placeholder='Series'
            />
            <Form.Item name={`${id}days_tracked`}>
              <Select
                placeholder='Days Tracked'
                className='filter-select'
                showArrow
                allowClear
                style={{ width: '100%' }}
              >
                <Option value='120'>Less than 120 days</Option>
                <Option value='90'>Less than 90 days</Option>
                <Option value='60'>Less than 60 days</Option>
                <Option value='30'>Less than 30 days</Option>
              </Select>
            </Form.Item></>)}
          {RoyalRoadTabs.includes(id) && id !== AUTHORS && id !== PODIUMAUTHORS && (
            <><FilterItem
              point='fiction_name'
              placeholder='Titles'
              selectProps={selectProps}
              id={id}
            /><FilterItem
              selectProps={selectProps}
              point='author_name'
              placeholder='Author'
              id={id}
            />
            <FilterItemTag
              selectProps={selectProps}
              point='warning_tags'
              placeholder='Tags'
              id={id}
            />
            <FilterItem
              selectProps={selectProps}
              point='fiction_tags'
              placeholder='Genres'
              id={id}
            />
            <Form.Item name={`${id}status_tag`}>
              <Select
                placeholder='Status'
                className='filter-select'
                showArrow
                allowClear
                style={{ width: '100%' }}
                mode={'multiple'}
              >
                <Option value='COMPLETED'>Completed</Option>
                <Option value='DROPPED'>Dropped</Option>
                <Option value='HIATUS'>Hiatus</Option>
                <Option value='ONGOING'>Ongoing</Option>
                <Option value='STUB'>Stub</Option>
              </Select>
            </Form.Item>
            <Form.Item name={`${id}type_tag`}>
              <Select
                placeholder='Type'
                className='filter-select'
                showArrow
                allowClear
                style={{ width: '100%' }}
              >
                <Option value='Original'>Original</Option>
                <Option value='Fan Fiction'>Fan Fiction</Option>
              </Select>
            </Form.Item>
            </>)}
          {(id === AUTHORS || id === PODIUMAUTHORS) &&
            (<><Form.Item name={`${id}reputation`}>
              <Select
                placeholder='Reputation Level'
                className='filter-select'
                showArrow
                allowClear
                tokenSeparators={[',']}
                style={{ width: '100%' }}
                mode={'tags'}
              >
                {
                  [...Array(100)].map((_, i) => i + 1).map((val) =>
                    <Option value={`${val}`} key={`option-${val}`}>{val}</Option>)
                }
              </Select>
            </Form.Item>
            <Form.Item name={`${id}author_tags`}>
              <Select
                placeholder='Authors Tag'
                className='filter-select'
                showArrow
                allowClear
                style={{ width: '100%' }}
                mode={'multiple'}
              >
                <Option value='watchlist'>Watchlist</Option>
                <Option value='excluded'>Excluded</Option>
              </Select>
            </Form.Item></>)}
          {DailyRankTabs.includes(id) && (
            <>
              <FilterItem
                selectProps={selectProps}
                point='author'
                placeholder='Author'
                id={id}
              />
              <FilterItem
                id={id}
                selectProps={selectProps}
                point='series'
                placeholder='Series'
              />
              <FilterItem
                selectProps={selectProps}
                point='genre_categories'
                placeholder='Genre Categories'
                id={id}
              />
              <Form.Item name={`${id}date_range`}>
                <AutoComplete
                  className='filter-select'
                  placeholder='Date Range'
                  showArrow
                  allowClear
                >
                  {
                    [...Array(11)].map((_, i) => i * 30 + 30).map((val) =>
                      <Option value={val} key={`option-${val}`}>{val} days</Option>)
                  }
                  <Option value={365}>365 days</Option>
                </AutoComplete>
              </Form.Item>
            </>
          )}
          {(id === ALL_NEW_TITLE || id === PENDING_EXCLUSION || id === WATCHLIST) && (
            <Form.Item name={`${id}late_bloomer`}>
              <Select
                placeholder='All Titles'
                className='filter-select'
                showArrow
                allowClear
                style={{ width: '100%' }}
                value={filter[id]['late_bloomer']}
              >
                <Option value={'hot_titles'}>
                  Show Hot Titles <FireTwoTone twoToneColor={'red'} />
                </Option>
              </Select>
            </Form.Item>
          )}
          {(RoyalRoadTabs.includes(id) && id !== AUTHORS && id !== RISING_STARS) &&
            (
              <Select
                className='filter-select'
                showArrow
                onChange={(value: string) => {
                  if (setSelectedOption) {
                    setSelectedOption(value);
                  }
                }}
                value={selectedOption}
              >
                <Select.Option value={'All Titles'}>All Titles</Select.Option>
                <Select.Option value={'Rising Stars'}>
                  Show Rising Stars <StarTwoTone twoToneColor={'#F6BE00'} />
                </Select.Option>
              </Select>
            )}
        </div>
      </Form>
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        {dataRefreshTime && (
          <JobStatus
            dataRefreshTime={dataRefreshTime}
            isBackendJobComplete={isBackendJobComplete}
          />
        )}
        <SaveSearchButton
          tab={id}
          filters={{ ...filter[id], option: selectedOption }}
          sort={sort[id]}
          source={sourcePath['source']}
        />
        <StyledReset type='submit' onClick={() => { handleReset(); }}>
          <UndoOutlined />
        </StyledReset>
      </div>
    </div>
  );
};

export default React.memo(FilterForm);