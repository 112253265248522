import { FlagOutlined } from '@ant-design/icons';
import React from 'react';
import { notification, Popover } from 'antd';
import { IBook } from '../../section/Common/types/types';
import { PENDING_EXCLUSION, PENDING_EXCLUSION_RR, SEND_TO_CRM, SENT_TO_CRM_RR, SOURCE } from '../../core/constants';
import { useUpdateTitle } from '../../core/modules/book/hooks/useUpdateTitle.query';
import { useActions } from '../../context/Actions.context';

interface IProps {
  book: IBook;
  refetchTable: Function;
  id: string;
}

const createExclusionObject = (source: string, book: IBook) => {
  if (source === SOURCE.bookstat) {
    return { amazonasin: book.ASIN };
  } else {
    return { fiction_id: book['Fiction Id'] };
  }
};

const getExclusionType = (source: string) => {
  if (source === SOURCE.bookstat) {
    return PENDING_EXCLUSION;
  } else {
    return PENDING_EXCLUSION_RR;
  }
};

const ToPendingExclusion: React.FC<IProps> = ({ book, id, refetchTable }) => {
  const { source: sourcePath, setActionLoading } = useActions();
  const pendingExclusion = createExclusionObject(sourcePath['source'], book);
  const exclusionType = getExclusionType(sourcePath['source']);
  const { mutateAsync } = useUpdateTitle(exclusionType);

  const onSend = (e) => {
    setActionLoading(true);
    e.stopPropagation();
    window.gtag('event', 'click', { event_category: 'Interaction', event_label: 'Send To Pending Exclusion' });
    mutateAsync(pendingExclusion)
      .then(res => {
        if (res?.status === 200) {
          notification.success({
            description: `Success: Sent ${book.Title}`,
            message: 'The Title was successfully sent to the Pending Exclusion tab.'
          });

          if (id === SEND_TO_CRM || id === SENT_TO_CRM_RR) {
            notification.info({
              description: 'Please delete the data from the Hubspot.',
              message: ''
            });
          }
          refetchTable();
        }
      })
      .catch(() => {
        window.gtag('event', 'exception',
          { event_category: 'Error', event_label: 'Error: Send To Pending Exclusion tab.' }
        );
        notification.error({
          description: `Error while sending ${book.Title} to the Pending Exclusion tab.`,
          message: 'Failed to send title.'
        });
        setActionLoading(false);
      });
  };

  const content = (
    <div className='popover-content'>Send Title to Pending Exclusion</div>
  );

  return (
    <Popover content={content} trigger='hover'>
      <FlagOutlined
        data-testid='action-pending-list'
        onClick={onSend}
        className='actions-icons actions-icons-flag'
      />
    </Popover>
  );
};

export default ToPendingExclusion;