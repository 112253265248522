import { useMutation } from 'react-query';
import { useActions } from '../../../../context/Actions.context';
import Endpoints from '../../../constants/endpoints';
import { useServices } from '../../../services/useServices';
import { IGetBookListParams } from '../book.dto';

export const useUpdateTitle = (name) => {
  const {
    book: { updateTitle }
  } = useServices();

  const { source: sourcePath } = useActions();
  const sourceTab = window.location.pathname.split('/')[2];

  return useMutation(
    'add-to-list',
    (param: IGetBookListParams) =>
      updateTitle(
        {
          source: sourcePath['source'],
          sourceTab,
          endpoint: Endpoints[name],
          action: name
        },
        param
      )
  );
};
