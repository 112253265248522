import { useEffect, useState, useMemo } from 'react';
import { useBooksList, useDataRowCount } from '../../../core/modules/book/hooks';
import { COMPETITORS } from '../../../core/constants';
import { BlockAuthor, Delete, Return, ToCRM, Watch } from '../../../composed-components/Actions';
import { BASEFONTSIZE, COLUMNSHEADING, PAGESIZE, REFETCHDELAY, RefetchOptions } from '../constants';
import { EFontSize, TColumns } from '../types/types';
import { FilterForm, Settings, Sorting, SpinnerTabs } from '../../../composed-components';
import ResizeTable from '../../../composed-components/Resize';
import { useFilter } from '../../../context/Filter.context';
import { useSort } from '../../../context/Sort.context';
import ToPodiumTitle from '../../../composed-components/Actions/ToPodiumTitle';
import Pagination from '../../../components/Pagination';
import getAllColumns from '../columns/getAllColumns';
import { useUserPermit } from '../../../context/User.context';
import { useActions } from '../../../context/Actions.context';
import AlsoBought from '../../../composed-components/AlsoBought';
import getDataSource from '../utils/getDataSource';

const Competitors = () => {
  const { edit_titles, move_titles_hubspot } = useUserPermit();
  const [fontSize, setFontSize] = useState<keyof typeof EFontSize>(BASEFONTSIZE);
  const [page, setPage] = useState<number>(1);
  const { isActionLoading, setActionLoading, isRefreshed } = useActions();
  const { filter } = useFilter();
  const { sort } = useSort();
  const [deleteItems, setDeleteItems] = useState<string[]>([]);
  const changeFontSize = size => setFontSize(size);
  const changePage = p => setPage(p);
  const deleteBooks = book => setDeleteItems(book);

  const { data = [], isLoading, isFetching, refetch } = useBooksList(
    COMPETITORS, {
      page,
      ...filter[COMPETITORS],
      ...sort[COMPETITORS]
    });

  const { count, refetch: refetchRowCount } = useDataRowCount(COMPETITORS, { ...filter[COMPETITORS] });

  const refetchTable = () => {
    setTimeout(() => {
      refetch();
      refetchRowCount();
      setActionLoading(false);
    }, REFETCHDELAY);
  };

  const sortColumns = (title: string) => (
    <Sorting id={COMPETITORS} title={title} changePage={changePage} />
  );

  const thead = useMemo(() => {
    const allColumns = getAllColumns(sortColumns);
    const head = COLUMNSHEADING[COMPETITORS].map(title => {
      if (title === 'Podium Hit Count') {
        return {
          ...allColumns[title],
          render(text, book) {
            const result = <AlsoBought podiumHitCount={text === null ? -1 : text} asin={book.key}/>;

            return {
              children: result
            };
          }
        };
      }
      return allColumns[title];
    });

    if (edit_titles) {
      head.push({
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        className: 'show td-actions',
        fixed: 'right',
        width: 90,
        render: (_, book) => (
          <div className='actions'>
            <Delete book={book} id={COMPETITORS} />
            <BlockAuthor book={book} id={COMPETITORS} />
            <ToPodiumTitle
              book={book}
              refetchTable={refetchTable}
              id={COMPETITORS}
            />
            <Watch book={book} refetchTable={refetchTable} id={COMPETITORS} />
            {move_titles_hubspot && <ToCRM book={book} id={COMPETITORS} />}
            <Return book={book} id={COMPETITORS} />
          </div>
        )
      });
    }

    return head;
  }, [edit_titles, move_titles_hubspot, sortColumns]);

  const [columns, setColumns] = useState<TColumns[]>(thead);
  const dataSource = getDataSource(data);

  useEffect(() => {
    if (!isRefreshed) {
      refetch(RefetchOptions);
      refetchRowCount(RefetchOptions);
    }
  }, [page, filter[COMPETITORS], sort[COMPETITORS]]);

  return (
    <>
      <div className={'wrapper-controller'}>
        <FilterForm id={COMPETITORS} page={page} changePage={changePage} />
        <Settings
          thead={thead}
          refetchTable={refetchTable}
          id={COMPETITORS}
          changeFontSize={changeFontSize}
          setColumns={setColumns}
          deleteBooks={deleteBooks}
          deleteItems={deleteItems}
        />
      </div>
      {!isLoading ? (
        <>
          <ResizeTable
            fontSize={fontSize}
            id={COMPETITORS}
            dataSource={dataSource}
            deleteItems={deleteItems}
            deleteBooks={deleteBooks}
            isFetching={isFetching || isActionLoading}
            columns={columns}
            refetchTable={refetchTable}
          />
          <Pagination
            total={count}
            current={page}
            onChange={setPage}
            pageSize={PAGESIZE}
          />
        </>
      ) : (
        <SpinnerTabs isLoading={isLoading} />
      )}
    </>
  );
};

export default Competitors;