import React, { useState } from 'react';
import { Row, Col, notification } from 'antd';
import { useQueryClient } from 'react-query';
import { useUpdateTitle } from '../../../core/modules/book/hooks/useUpdateTitle.query';
import { DELETE, SEND_TO_CRM, SENT_TO_CRM_RR } from '../../../core/constants';
import { IBook } from '../../../section/Common/types/types';
import showNotification from '../../../section/Common/utils/showNotification';
import getTags from '../../../section/Common/utils/getTags';
import Modal from '../../../components/Modal';
import { Field, Form, Formik } from 'formik';
import { Title } from '../../../components/styles';
import { Checkbox, CheckBoxGroup } from '../../../components/Checkbox';
import { useActions } from '../../../context/Actions.context';
import Input from '../../../components/Input';

interface IDeleteOptions {
  ASIN: string;
  Title?: string;
}

interface IProps {
  book: IBook | IDeleteOptions;
  closeModal: Function;
  modal: boolean;
  removeDelete?: Function;
  id: string;
}

const checkboxTrash = [
  'Public Domain Title',
  'Irrelevant Content',
  'Foreign Language'
];

const DeleteTitles: React.FC<IProps> = ({
  book,
  modal,
  closeModal,
  removeDelete,
  id
}) => {
  const { ASIN, Title: bookTitle } = book;
  const { setActionLoading } = useActions();
  const fiction_id = book['Fiction Id'];
  const queryClient = useQueryClient();

  const { mutateAsync } = useUpdateTitle(DELETE);
  const [isCheck, setIsCheck] = useState<boolean>(false);

  const onDelete = (values, cb) => {
    setActionLoading(true);
    let tag = getTags(values?.tags);

    if (isCheck) {
      tag += `&tag=${values?.checkboxInput}`;
    }

    const promise = mutateAsync(ASIN ?
      { amazonasin: values?.amazonasin, tag } :
      { fiction_id: values?.fiction_id, tag });

    cb.resetForm();
    showNotification(
      promise,
      'The Title(s) were successfully deleted.',
      'The Title(s) have not been deleted.',
      bookTitle
    );
    promise.then(() => {
      if (id === SEND_TO_CRM || id === SENT_TO_CRM_RR) {
        notification.info({
          description: 'Please delete the data from Hubspot.',
          message: ''
        });
      }
    });
    promise.then(() => queryClient.refetchQueries(`get-row-count${id}`));
    promise.then(() => setActionLoading(false));
    promise.then(() => queryClient.refetchQueries(`get-${id}`));

    if (removeDelete) {
      removeDelete();
    }

    closeModal();
  };

  return (
    <Formik
      initialValues={{
        tags: [],
        checkboxInput: '',
        amazonasin: ASIN,
        fiction_id
      }}
      enableReinitialize={true}
      onSubmit={onDelete}
    >
      {({ values, handleSubmit, resetForm }) => (
        <Form>
          <Modal
            okText='Delete'
            title='Delete Titles'
            visible={modal}
            onOk={handleSubmit}
            okButtonProps={{
              disabled: !(
                values?.tags?.length ||
                (isCheck && values?.checkboxInput)
              )
            }}
            onCancel={() => {
              setIsCheck(false);
              resetForm();
              closeModal();
            }}
          >
            <Title>Select reason(s) for deleting titles:</Title>

            <Row>
              <Field name='tags'>
                {({
                  field: { value, name },
                  form: { setFieldValue, setFieldTouched }
                }) => (
                  <CheckBoxGroup
                    value={value}
                    name={name}
                    onChange={v => {
                      setFieldValue(name, v);
                      setFieldTouched(name, true, false);
                    }}
                  >
                    {checkboxTrash.map((reason) => (
                      <Col span={24} key={reason} style={{ marginBottom: 8 }}>
                        <Checkbox value={reason}>{reason}</Checkbox>
                      </Col>
                    ))}
                  </CheckBoxGroup>
                )}
              </Field>
              <Col span={24} style={{ marginBottom: 8 }}>
                <Checkbox
                  data-testid={'input-checkbox'}
                  checked={isCheck}
                  onChange={() => {
                    setIsCheck(state => !state);
                  }}
                >
                  <Field name={'checkboxInput'}>
                    {({ field: { value, onChange, ...rest } }: any) => (
                      <Input
                        name={'checkboxInput'}
                        placeholder='Enter a reason'
                        value={value}
                        disabled={!isCheck}
                        onChange={event => {
                          onChange(event);
                        }}
                        {...rest}
                      />
                    )}
                  </Field>
                </Checkbox>
              </Col>
            </Row>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default React.memo(DeleteTitles);