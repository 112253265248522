import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Table from '../../components/Table';
import { Popover, Modal, notification } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useBrendaSearches } from '../../core/modules/book/hooks/useBrendaSearches';
import Endpoints from '../../core/constants/endpoints';
import { useUserPermit } from '../../context/User.context';
import UserSort from '../Users/UserSort';

const Wrapper = styled.div`
  width: 100%;
`;

const SavedSearchSection: React.FC = () => {
  const savedSearches = useBrendaSearches('get');
  const deleteSearch = useBrendaSearches('delete');

  const { id: user_id, searchData, updateSearchData } = useUserPermit();
  const { data, isLoading } = savedSearches;

  const [searchId, setSearchId] = useState();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [sorts, setSorts] = useState({ sortBy: 'creation_time', sortType: 'descending' });

  useEffect(() => {
    savedSearches.mutate({
      user_id,
      ...sorts
    });
  }, [sorts]);

  const handleDelete = (id) => {
    setSearchId(id);
    setOpenDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    deleteSearch.mutate(
      { search_id: searchId },
      {
        onSuccess: (res) => {
          if (res?.success) {
            notification.success({
              message: res?.msg,
              description: ''
            });
            savedSearches.mutate({ user_id });
            updateSearchData(searchData
              .filter(item => item['search_id'] !== searchId)
            );
          } else {
            notification.error({
              message: 'Failed to delete.',
              description: res?.msg
            });
          }
        },
        onError: () => {
          notification.error({
            message: 'Failed to delete search result.',
            description: 'Please try again.'
          });
        }
      }
    );
    setOpenDeleteModal(false);
  };

  const columns = [
    {
      title: () => (
        <UserSort
          title={'Search Name'}
          id={'search_name'}
          sorts={sorts}
          setSort={setSorts}
        />
      ),
      dataIndex: 'search_name',
      key: 'search_name',
      className: 'show',
      render: (name: string, _obj) => {
        if (name) {
          return {
            children: (
              <div>
                <a href={`${_obj.source}${Endpoints[_obj.tab]}?${_obj.search_query}`}
                  target='_blank' rel='noopener noreferrer' style={{ color: '#00b27b' }}>
                  {name}
                </a>
              </div>
            )
          };
        }
      }
    },
    {
      title: () => (
        <UserSort
          title={'Created'}
          id={'creation_time'}
          sorts={sorts}
          setSort={setSorts}
        />
      ),
      dataIndex: 'creation_time',
      key: 'creation_time',
      className: 'show',
      width: 220
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      className: 'show td-actions',
      fixed: 'right',
      width: 150,
      render: (_, row) => (
        <div className='actions'>
          <div>
            <Popover
              content={<div className='popover-content'>Delete Search</div>}
              trigger='hover'
            >
              <DeleteOutlined
                onClick={() => {
                  window.gtag('event', 'click', { event_category: 'Interaction', event_label: 'Delete Search Result' });
                  handleDelete(row.search_id);
                }}
                className='actions-icons actions-icons-trash'
              />
            </Popover>
          </div>
        </div>
      )
    }
  ];

  return (
    <Wrapper>
      <Table
        bordered
        dataSource={data || []}
        pagination={true}
        columns={columns}
        x={0}
        loading={isLoading || deleteSearch.isLoading}
      />
      <Modal
        visible={openDeleteModal}
        title='Remove Search'
        onOk={handleConfirmDelete}
        onCancel={() => setOpenDeleteModal(false)}
        okText='Yes'
        cancelText='No'
        confirmLoading={deleteSearch.isLoading}
      >
        Are you sure you wish to delete this search?
      </Modal>
    </Wrapper>
  );
};

export default SavedSearchSection;