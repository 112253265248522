import React, { createContext, useContext } from 'react';
import { IServices } from '.';

interface IServicesProviderParams {
  readonly children: React.ReactNode;
  readonly services: IServices;
}

export const ServicesContext = createContext<IServices>({} as IServices);

export function ServicesProvider({
  children,
  services
}: IServicesProviderParams) {
  return (
    <ServicesContext.Provider value={services}>
      {children}
    </ServicesContext.Provider>
  );
}

export function useServices(): IServices {
  return useContext(ServicesContext);
}
