import './styles.css';
import { notification } from 'antd';
import Modal from '../../components/Modal';
import Input from '../../components/Input';
import FormItem from '../../components/FormItem';
import { Field, FormikProvider, useFormik } from 'formik';
import { useBrendaSearches } from '../../core/modules/book/hooks/useBrendaSearches';

const checkSearchName = (name: string, searchData: { search_name: string }[]): boolean =>
  searchData.some(item => item.search_name.toLowerCase() === name.toLowerCase());

const SaveSearchName = ({ onClose, open, payload, searchData, updateSearchData }): JSX.Element => {
  const saveSearchMutation = useBrendaSearches('save');

  const formik = useFormik({
    initialValues: {
      name: '',
      ...payload
    },
    onSubmit: async () => null,
    enableReinitialize: true
  });

  const handleSave = () => {
    saveSearchMutation.mutate(
      { ...formik.values },
      {
        onSuccess: (res) => {
          onClose();

          if (res?.success) {
            notification.success({
              message: res?.msg || '',
              description: ''
            });
            updateSearchData([...searchData, ...res.data || []]);
          } else {
            notification.error({
              message: 'Failed to save result.',
              description: res.msg || ''
            });
          }
        },
        onError: () => {
          onClose();
          notification.error({
            message: 'Failed to save search result.',
            description: 'Please try again.'
          });
        }
      }
    );
  };

  const validateInput = (name: string): string | undefined => {
    if (!name) {
      return 'Name is required.';
    }

    if (checkSearchName(name, searchData)) {
      return 'This name already exists. Please choose a different name.';
    }

    return undefined;
  };

  const onSend = async () => {
    handleSave();
  };

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const remainingCharacters = 50 - formik.values.name.length;
  const characterCountText = `${remainingCharacters} / 50`;

  return (
    <Modal
      okText='Save'
      title='Save New Search'
      cancelText='Cancel'
      visible={open}
      onOk={onSend}
      okButtonProps={{
        disabled: !formik.values.name || checkSearchName(formik.values.name, searchData)
      }}
      onCancel={handleClose}
      confirmLoading={saveSearchMutation.isLoading}
    >
      <FormikProvider value={formik}>
        <FormItem label='Name' required error={formik.errors.name}>
          <Field name='name' validate={validateInput}>
            {({ field: { value, onChange }, form: { validateForm } }) => (
              <div className='save-search-input-container'>
                <Input
                  id='name'
                  name='name'
                  value={value}
                  placeholder='Enter Search Result Name...'
                  onChange={onChange}
                  onBlur={() => {
                    validateForm();
                  }}
                  maxLength={50}
                />
                <div className='character-count'>{characterCountText}</div>
              </div>
            )}
          </Field>
        </FormItem>
      </FormikProvider>
    </Modal>
  );
};

export default SaveSearchName;