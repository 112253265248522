import React from 'react';
import { Popover } from 'antd';
import { SyncOutlined } from '@ant-design/icons';

type JobStatusProps = {
    dataRefreshTime: string;
    isBackendJobComplete?: boolean;
};

const JobStatus: React.FC<JobStatusProps> = ({ isBackendJobComplete = true, dataRefreshTime }) => (
  <div className='job-status-container'>
    {!isBackendJobComplete && (
      <Popover content={<div className='popover-content'>Data is in processing state...</div>} trigger='hover'>
        <SyncOutlined spin />
      </Popover>
    )}
    {dataRefreshTime && (
      <div className='data-refresh-time'>
        Last processing run: {dataRefreshTime}
      </div>
    )}
  </div>
);

export default JobStatus;