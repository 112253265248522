import React, { useContext, useMemo, useState } from 'react';
import DeleteTitles from '../composed-components/Actions/Delete/DeleteTitles';
import ExcludeTitles from '../composed-components/Actions/Exclude/ExcludeTitles';
import SendToCRM from '../composed-components/Actions/ToCRM/SendToCRM';
import BlockAuthorTitle from '../composed-components/Actions/BlockAuthor/BlockAuthorTitle';

const ActionsContext = React.createContext({
  setAction: values => console.log(values),
  source: '/bookstat',
  isActionLoading: false,
  setActionLoading: (value: boolean) => console.log(value),
  isRefreshed: false,
  setIsRefreshed: (value: boolean) => console.log(value)
});

export const ACTIONS = {
  delete: 'delete',
  return: 'return',
  watch: 'watch',
  exclude: 'exclude',
  toCRM: 'toCRM',
  blockAuthor: 'blockAuthor'
};

const defaultActionObject = {
  type: null,
  book: { ASIN: '', id: '', Title: '', Author: '' },
  id: ''
};

export const ActionsProvider = ({ children, source }) => {
  const [action, setAction] = useState(defaultActionObject);
  const [actionLoading, setActionLoading] = useState(false);
  const [isRefreshed, setIsRefreshed] = useState(false);

  const contextValue = useMemo(() => ({
    setAction,
    source,
    isActionLoading: actionLoading,
    setActionLoading,
    isRefreshed,
    setIsRefreshed
  }), [setAction, source, actionLoading, setActionLoading, isRefreshed, setIsRefreshed]);

  return (
    <ActionsContext.Provider value={contextValue}>
      <DeleteTitles
        book={action?.book}
        id={action?.id}
        modal={action?.type === ACTIONS.delete}
        closeModal={() => setAction(defaultActionObject)}
      />
      <ExcludeTitles
        book={action?.book}
        id={action.id}
        modal={action?.type === ACTIONS.exclude}
        toggleModal={() => setAction(defaultActionObject)}
      />
      <SendToCRM
        book={action?.book}
        id={action.id}
        modal={action?.type === ACTIONS.toCRM}
        toggleModal={() => setAction(defaultActionObject)}
      />
      <BlockAuthorTitle
        book={action?.book}
        id={action.id}
        modal={action?.type === ACTIONS.blockAuthor}
        closeModal={() => setAction(defaultActionObject)}
      />
      {children}
    </ActionsContext.Provider>
  );
};

export const ActionsConsumer = ActionsContext.Consumer;

export const useActions = () => {
  const context = useContext(ActionsContext);

  return context;
};

export default ActionsContext;