import { LineChartOutlined } from '@ant-design/icons';
import React, { CSSProperties, useState } from 'react';
import { Popover } from 'antd';
import ReactDOM from 'react-dom';
import { Area } from '@ant-design/charts';
import { IBook } from '../../../section/Common/types/types';

interface IProps {
  book: IBook;
}

const splitData = data => data?.split(',');

const style: CSSProperties = {
  textAlign: 'center',
  color: 'rgb(91 89 156 / 98%)',
  margin: '10px',
  fontWeight: 500
};

const getConfig = data => ({
  data,
  xField: 'date',
  yField: 'value',
  slider: {
    start: 0,
    end: 1
  },
  areaStyle: function areaStyle() {
    return { fill: 'l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff' };
  }
});

const getChart = (data, value, name) => {
  const chart = data.map((el, id) => ({
    date: el,
    value: value[id]
  }));

  const config = getConfig(chart);

  return (
    <div
      style={{
        background: 'white',
        borderRadius: '10px',
        padding: '20px',
        marginBottom: '30px'
      }}
    >
      <h2 style={style}>{name}</h2>
      <div
        style={{
          width: '100%'
        }}
      >
        <Area className={'chart'} {...config} />
      </div>
    </div>
  );
};

const Chart: React.FC<IProps> = ({ book }) => {
  const [isShow, setIsShow] = useState(true);

  const { Title, Author } = book;

  const lifetimeDateEnd = splitData(book['Lifetime Sales End Date']);
  const lifetimeUnits = splitData(book['Lifetime Audiobooks Sold']);
  const lifetimeSales = splitData(book['Lifetime Sales']);

  const trailingYearDateEnd = splitData(book['Trailing Year Sales End Date']);
  const trailingYearUnits = splitData(book['Trailing Year Audiobooks Sold']);

  const firstYearDateEnd = splitData(book['First Year Sales End Date']);
  const firstYearUnits = splitData(book['First Year Audiobooks Sold']);

  const children = (
    <div style={{ margin: '0 auto', maxWidth: 1140 }}>
      <h2 style={{ color: '#636363f9', textAlign: 'center', margin: '30px' }}>
        {Title}
      </h2>
      {lifetimeDateEnd &&
        lifetimeUnits &&
        getChart(lifetimeDateEnd, lifetimeUnits, 'Lifetime Units')}

      {lifetimeDateEnd &&
        lifetimeSales &&
        getChart(lifetimeDateEnd, lifetimeSales, 'Lifetime Sales')}

      {trailingYearDateEnd &&
        lifetimeUnits &&
        getChart(trailingYearDateEnd, trailingYearUnits, 'Trailing Year Units')}

      {firstYearDateEnd &&
        firstYearUnits &&
        getChart(firstYearDateEnd, firstYearUnits, 'First Year Units')}
    </div>
  );

  const containerEl = document.createElement('div');

  const onOpen = () => {
    window.gtag('event', 'view_item', { event_category: 'View', event_label: 'charts' });
    setIsShow(false);
    const externalWindow = window.open(
      '',
      `${Author}: ${Title}`,
      `width=${Number(document.body.clientWidth) - 1},height=${Number(
        document.body.clientHeight
      )}`
    );

    if (externalWindow) {
      externalWindow.document.title = `${Author}: "${Title}"`;
      externalWindow.document.body.style.background = '#f5f6f8';
    }

    ReactDOM.render(children, containerEl);
    externalWindow?.document.body.appendChild(containerEl);

    externalWindow?.addEventListener('beforeunload', () => {
      setIsShow(true);
    });
  };

  return (
    <Popover
      content={<div className='popover-content'>Display chart</div>}
      trigger='hover'
    >
      {isShow ? (
        <LineChartOutlined
          data-testid={'chart-icon'}
          onClick={onOpen}
          className='actions-icons actions-icons-chart'
        />
      ) : (
        <LineChartOutlined
          style={{ color: 'grey' }}
          className='actions-icons'
        />
      )}
    </Popover>
  );
};

export default Chart;