import { useCallback, useEffect, useMemo, useState } from 'react';
import { HOT_TITLES, SOURCE, ALL_NEW_TITLE } from '../../../core/constants';
import { getAllNewTitles } from '../columns/getAllNewTitles';
import { useBooksList, useDataRowCount } from '../../../core/modules/book/hooks';
import { Delete, ToCRM, Watch, BlockAuthor } from '../../../composed-components/Actions';
import { BASEFONTSIZE, PAGESIZE, REFETCHDELAY, RefetchOptions } from '../constants';
import { FilterForm, Settings, Sorting, SpinnerTabs } from '../../../composed-components';
import ResizeTable from '../../../composed-components/Resize';
import { EFontSize, TColumns } from '../types/types';
import ToPodiumTitle from '../../../composed-components/Actions/ToPodiumTitle';
import ToPendingExclusion from '../../../composed-components/Actions/ToPendingExclusion';
import Pagination from '../../../components/Pagination';
import { useFilter } from '../../../context/Filter.context';
import { useSort } from '../../../context/Sort.context';
import { useUserPermit } from '../../../context/User.context';
import Legend from '../../../composed-components/Legend';
import { TableFooter } from '../../../components/Table';
import { useActions } from '../../../context/Actions.context';
import getDataSource from '../utils/getDataSource';
import { applyRoleBasedFilters } from '../utils/roleBasedFilters';

const HotTitles = () => {
  const [fontSize, setFontSize] = useState<keyof typeof EFontSize>(BASEFONTSIZE);
  const [page, setPage] = useState<number>(1);
  const [deleteItems, setDeleteItems] = useState<string[]>([]);

  // TODO
  const [selectedOption, setSelectedOption] = useState<string>('All Titles');
  const [isFilterInitialized, setIsFilterInitialized] = useState<boolean>(false);

  const { source: sourcePath, isActionLoading, setActionLoading, isRefreshed } = useActions();
  const { edit_titles, move_titles_hubspot, role, default_filters: userDefaultFilters } = useUserPermit();
  const { filter, setFilter } = useFilter();
  const { sort } = useSort();

  const changePage = p => setPage(p);
  const deleteBooks = book => setDeleteItems(book);
  const changeFontSize = size => setFontSize(size);

  const isBookstat = sourcePath['source'].includes(SOURCE.bookstat);

  // TODO
  const { data = [], isLoading, isFetching, refetch, dataRefreshTime } = useBooksList(
    HOT_TITLES, {
      page,
      ...filter[HOT_TITLES],
      ...sort[HOT_TITLES]
    });

  const { count, refetch: refetchRowCount } = useDataRowCount(HOT_TITLES, { ...filter[HOT_TITLES] });

  const refetchTable = () => {
    setTimeout(() => {
      refetch();
      refetchRowCount();
      setActionLoading(false);
    }, REFETCHDELAY);
  };

  const sortComponent = useCallback(
    (title: string) => (
      <Sorting id={HOT_TITLES} title={title} changePage={changePage} />
    ),
    []
  );

  const thead = useMemo(() => {
    const columns = getAllNewTitles(sortComponent, HOT_TITLES);

    if (edit_titles) {
      columns.push({
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        className: 'show td-actions',
        fixed: 'right',
        width: 90,
        render: (_, book) => (
          <div className='actions'>
            <Delete book={book} id={HOT_TITLES} />
            <BlockAuthor book={book} id={HOT_TITLES} />
            <ToPendingExclusion
              book={book}
              refetchTable={refetchTable}
              id={HOT_TITLES}
            />
            <ToPodiumTitle book={book} refetchTable={refetchTable} id={HOT_TITLES} />
            <Watch book={book} refetchTable={refetchTable} id={HOT_TITLES} />
            {move_titles_hubspot && <ToCRM book={book} id={HOT_TITLES} />}
          </div>
        )
      });
    }

    return columns;
  }, [edit_titles, move_titles_hubspot, sortComponent]);

  const [columns, setColumns] = useState<TColumns[]>(thead);
  const dataSource = getDataSource(data, selectedOption, isBookstat);

  useEffect(() => {
    if (!isFilterInitialized) {
      if (!window.location.search) {
        const updatedFilters = applyRoleBasedFilters(filter, role, userDefaultFilters);

        setFilter(prevFilters => ({
          ...prevFilters,
          ...updatedFilters
        }));
      }

      setIsFilterInitialized(true);
    }
  }, []);

  useEffect(() => {
    if (!isRefreshed && isFilterInitialized) {
      refetch(RefetchOptions);
      refetchRowCount(RefetchOptions);
    }
    return () => {
      setSelectedOption('All Titles');
    };
  }, [page, filter[HOT_TITLES], sort[HOT_TITLES], isFilterInitialized]);

  return (
    <>
      <div className={'wrapper-controller'}>
        <FilterForm
          id={HOT_TITLES}
          page={page}
          changePage={changePage}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          dataRefreshTime={dataRefreshTime}
        />
        <Settings
          thead={thead}
          refetchTable={refetchTable}
          id={HOT_TITLES}
          changeFontSize={changeFontSize}
          setColumns={setColumns}
          deleteBooks={deleteBooks}
          deleteItems={deleteItems}
        />
      </div>

      {!isLoading ? (
        <>
          <ResizeTable
            fontSize={fontSize}
            id={HOT_TITLES}
            dataSource={dataSource}
            deleteItems={deleteItems}
            isFetching={isFetching || isActionLoading}
            deleteBooks={deleteBooks}
            columns={columns}
            refetchTable={refetchTable}
          />
          <TableFooter>
            {isBookstat ?
              (<Legend map={['crm', 'podium', 'price']} />) :
              (<Legend map={['crm', 'podium']} />)}
            <Pagination
              total={count}
              current={page}
              onChange={setPage}
              pageSize={PAGESIZE}
            />
          </TableFooter>
        </>
      ) : (
        <SpinnerTabs isLoading={isLoading} />
      )}
    </>
  );
};

export default HotTitles;