import { defaultFilter } from '../../../context/Filter.context';
import { TFilters } from '../types/types';
import { defaultSort } from '../../../context/Sort.context';
import getParsedData from './getParsedData';
import { applyRoleBasedFilters } from './roleBasedFilters';

export const getFiltersForRole =
  (searchQuery: string, role?: string, userDefaultFilters?: string): Partial<TFilters> => {
    const updatedFilters = role && searchQuery === ''
      ? applyRoleBasedFilters(defaultFilter, role, userDefaultFilters)
      : defaultFilter;

    return updatedFilters;
  };

const getFilter = (getTabId: (string) => (string), userRole?: string, userDefaultFilters?: string) => {
  const searchQuery = window.location.search;
  const tab = window.location.pathname.split('/')[2];
  const tabId = getTabId(tab);
  const parsedData = getParsedData(searchQuery, tabId);

  const initialFilters = getFiltersForRole(searchQuery, userRole, userDefaultFilters);

  const modifiedFilter = { ...initialFilters };
  const modifiedSort = { ...defaultSort };

  if (parsedData?.filters) {
    modifiedFilter[tabId] = {
      ...initialFilters[tabId],
      ...parsedData.filters
    };
  }

  if (parsedData?.sort) {
    modifiedSort[tabId] = {
      ...defaultSort[tabId],
      ...parsedData.sort
    };
  }

  return {
    filter: Object.keys(parsedData?.filters).length === 0 ? modifiedFilter : { [tabId]: modifiedFilter[tabId] },
    sort: Object.keys(parsedData?.sort).length === 0 ? modifiedSort : { [tabId]: modifiedSort[tabId] }
  };
};

export default getFilter;