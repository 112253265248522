/* eslint-disable no-implicit-coercion */
import React, { useState } from 'react';
import { Button, Form, Row } from 'antd';
import Input from '../../components/Input';
import { useUpdateTitle } from '../../core/modules/book/hooks/useUpdateTitle.query';
import { TAGS } from '../../core/constants';
import { IBook } from '../../section/Common/types/types';
import { useUserPermit } from '../../context/User.context';
import { SOURCE } from '../../core/constants/endpoints';
import { useActions } from '../../context/Actions.context';

interface IProps {
  text: string;
  book: IBook;
}

const EditableCell: React.FC<IProps> = ({ text, book }) => {
  const { source: sourcePath } = useActions();
  const isBookstat = sourcePath['source'].includes(SOURCE.bookstat);
  const BookID = isBookstat ? book.ASIN : book['Fiction Id'];

  const { mutate } = useUpdateTitle(TAGS);
  const { edit_titles } = useUserPermit();
  const primaryTags = book['Primary Tags']?.split(',') || [];

  const splitTags = text ? text.split(',') : [];
  const existTags: string[] = [];

  for (const el of splitTags) {
    if (el) {
      existTags.push(el);
    }
  }

  const [tags, setTags] = useState(existTags);

  const getParams = val => {
    let tagsStr = '';

    if (val.length && val[0]) {
      tagsStr = val.reduce((acc: string, tag) => {
        // eslint-disable-next-line no-param-reassign
        acc += tag?.trim() ? `&tag=${tag}` : '';
        return acc;
      }, '');

      tagsStr = tagsStr.replace('&tag=', '');
    }

    return tagsStr;
  };

  const onSubmit = e => {
    if (e.code !== 'Enter' && e.type !== 'blur') {
      return null;
    }
    e.preventDefault();
    e.target.blur();
    const tagsStr = getParams(tags);

    mutate(isBookstat ?
      { amazonasin: BookID, tag: tagsStr } :
      { fiction_id: BookID, tag: tagsStr });
  };

  const onEdit = (_, { formTags }) => {
    setTags([...formTags]);

    const tagsStr = getParams(formTags);

    mutate(isBookstat ?
      { amazonasin: BookID, tag: tagsStr } :
      { fiction_id: BookID, tag: tagsStr });
  };

  return (
    <button className='basic-btn' onClick={(e) => e.stopPropagation()}>
      {primaryTags && !!primaryTags.length && primaryTags[0] && (
        <ul className={'list-primary-tags'}>
          {primaryTags.map((tag, idx) => (
            <li className={'primary-tags'} key={tag + idx}>
              {tag}
            </li>
          ))}
        </ul>
      )}
      <Form
        data-testid='tag-form'
        onKeyPress={onSubmit}
        onValuesChange={onEdit}
        initialValues={{ formTags: tags }}
      >
        <Form.List name='formTags'>
          {(fields, { add, remove }) => (
            <>
              {Boolean(fields.length) &&
                fields.map(field => (
                  <Row key={field.key} className={'cell-edit'}>
                    <Form.Item
                      data-testid='form-tags'
                      name={field.name}
                      fieldKey={field.fieldKey}
                      className={'cell-input'}
                    >
                      <Input onBlur={onSubmit} />
                    </Form.Item>
                    {edit_titles && (
                      <Button
                        data-testid='delete-tag'
                        className={'cell-delete'}
                        onClick={() => remove(field.name)}
                      >
                        x
                      </Button>
                    )}
                  </Row>
                ))}
              {edit_titles && (
                <button data-testid='add-tag' className={'cell-add-tags basic-btn'} onClick={() => add()}>
                  + Add tag
                </button>
              )}
            </>
          )}
        </Form.List>
      </Form>
    </button>
  );
};

export default React.memo(EditableCell);