import React, { useState, useCallback } from 'react';
import { useFiltering } from '../../core/modules/book/hooks/useFiltering';
import { Form, Select } from 'antd';
import debounce from 'debounce';

const { Option } = Select;

const FilterItem = ({ selectProps, point, placeholder, id }) => {
  const [enable, setEnable] = useState(false);
  const [search, setSearch] = useState('');
  const { data, isFetchingNextPage, fetchNextPage, hasNextPage } = useFiltering(
    point,
    id,
    enable,
    search
  );

  const onScroll = useCallback(
    event => {
      const { target } = event;

      if (
        hasNextPage &&
        target.scrollTop + target.offsetHeight === target.scrollHeight
      ) {
        fetchNextPage();
      }
    },
    [hasNextPage]
  );

  const options = data?.pages?.map(({ data: page }) =>
    page?.map(el => (
      <Option key={el} value={el}>
        {el}
      </Option>
    ))
  );

  const onSearch = debounce((value: string) => {
    setSearch(value.replace(/\s{2,}/g, ' ').trim());
  }, 2000);

  const onChange = (_, opt) => {
    if (!opt.length) {
      setSearch('');
    }
  };

  const optionsComponent = () => {
    if (options) {
      if (!hasNextPage) {
        return options;
      } else {
        return [
          ...options,
          <Option key='loading' value='loading' disabled={true}>
            Loading...
          </Option>
        ];
      }
    } else {
      return (
        <Option key='loading' value='loading' disabled={true}>
          Loading...
        </Option>);
    }
  };

  return (
    <Form.Item name={`${id}${point}`}>
      <Select
        onSearch={onSearch}
        onChange={onChange}
        onClick={() => setEnable(true)}
        loading={isFetchingNextPage}
        placeholder={placeholder}
        {...selectProps}
        style={{ width: '100%' }}
        onPopupScroll={onScroll}
        tokenSeparators={[',']}
        mode={'tags'}
      >
        {optionsComponent()}
      </Select>
    </Form.Item>
  );
};

export default React.memo(FilterItem);
