import { FormikProvider, useFormik } from 'formik';
import { useCallback, useMemo } from 'react';
import Modal from '../../../components/Modal';
import Conditions from './Conditions';
import styled from 'styled-components';
import { ConditionWrapper } from './styles';
import { useKeywordSearch } from '../../../core/modules/book/hooks/useKeywordSearch';
import Content from './Content';
import { useCreateCustomObject } from '../../../core/modules/book/hooks/useCreateCustomObject';
import { notification } from 'antd';
import ParentGenre from '../Genre/ParentGenre';

const Body = styled.div`
  display: flex;
  flex: 1;
  height: calc(98%);
`;

const MCondition = ({ formik, onSearch }) => (
  <FormikProvider value={formik}>
    <ConditionWrapper>
      <ParentGenre />
      <Conditions onSearch={onSearch} style={{ overflow: 'auto' }} disabled={!formik.values?.parent} />
    </ConditionWrapper>
  </FormikProvider>);

const CreateGenre = ({ open, onClose }) => {
  const formik = useFormik({
    initialValues: { search: {
      key: 'AND',
      children: [{
        text: ''
      }]
    },
    parent: '' },
    onSubmit: async () => null,
    enableReinitialize: true
  });

  const formik2 = useFormik({
    initialValues: {
      amazonasin: [],
      genres: ''
    },
    onSubmit: async () => null,
    enableReinitialize: true
  });

  const { data, isLoading, isFetching, refetch } = useKeywordSearch({
    search: formik.values.search
  }, {
    page: 1,
    per_page: 1000
  }, {
    enabled: false,
    cacheTime: 0
  });

  const createCustomObject = useCreateCustomObject();

  const handleCreate = () => {
    createCustomObject.mutate(
      {
        ...formik.values,
        genres: formik2.values?.genres,
        amazonasin: [
          ...(formik2.values?.amazonasin || []),
          ...(data?.data?.amazonasin || [])
        ]
      },
      {
        onSuccess: (res) => {
          onClose();

          if (res?.data?.success) {
            notification.success({
              description: res?.data.msg,
              message: 'Successfully created genre.'
            });
          } else {
            notification.error({
              description: res?.msg,
              message: 'Failed to create custom genre.'
            });
          }
        },
        onError: () => {
          onClose();
          notification.error({
            description: 'Failed to create genre.',
            message: ''
          });
        }
      }
    );
  };

  const handleSearch = useCallback(() => {
    refetch();
  }, []);

  const MemoMCondition = useMemo(() =>
    <MCondition onSearch={handleSearch} formik={formik} />,
  [formik, handleSearch]);

  return (
    <Modal
      title='Create custom genre'
      visible={open}
      width={'95%'}
      footer={null}
      onCancel={onClose}
      className={'create-genre-modal'}
    >
      <Body>
        {MemoMCondition}
        <Content
          data={data?.data}
          isLoading={isLoading}
          formik={formik2}
          isFetching={isFetching}
          handleCreate={handleCreate}
        />
      </Body>
    </Modal>
  );
};

export default CreateGenre;