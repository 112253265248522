import { TFilters } from '../types/types';

export const applyRoleBasedFilters = (
  initialFilters: TFilters,
  role?: string,
  default_filters = '{}'
): TFilters => {
  const parsedUserRoleDefaultFilters: Partial<TFilters> = JSON.parse(default_filters) as Partial<TFilters>;
  const roleSpecificFilters =
    role && parsedUserRoleDefaultFilters[role] ? parsedUserRoleDefaultFilters[role] : {};

  const updatedFilters = { ...initialFilters };

  Object.keys(roleSpecificFilters).forEach((tab) => {
    if (updatedFilters[tab]) {
      updatedFilters[tab] = {
        ...updatedFilters[tab],
        ...roleSpecificFilters[tab]
      };
    } else {
      updatedFilters[tab] = roleSpecificFilters[tab];
    }
  });

  return updatedFilters;
};
