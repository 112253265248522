import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

const ErrorMessage = () => <p>Something went wrong</p>;

export const GlobalErrorBoundary: React.FC = ({ children }) => {
  const handleError = (error: Error) => {
    // do smth else here and log error
    console.log(error);
  };

  const DefaultFallback = ErrorMessage;

  return (
    <ErrorBoundary FallbackComponent={DefaultFallback} onError={handleError}>
      {children}
    </ErrorBoundary>
  );
};
