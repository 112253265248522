import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spinner } from '../../components/Spinner';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { useExportData } from '../../core/modules/book/hooks/useExportData';
import { useFilter } from '../../context/Filter.context';
import { useSort } from '../../context/Sort.context';
import { IGetBook } from '../../core/modules/book/book.model';
import { useActions } from '../../context/Actions.context';
import { DAILY_RANK_EXPORT_ROWS } from '../../core/constants/global-constants';
import { SOURCE } from '../../core/constants';

enum ETypeFile {
  'csv',
  'xlsx',
  'pdf'
}

interface IProps {
  endpoint: string;
  checkedTitles: CheckboxValueType[];
  generateFile: (
    data: IGetBook[],
    id: string,
    checkedTitles: CheckboxValueType[],
    typeFile: string
  ) => void;
  typeFile: keyof typeof ETypeFile;
  text: string;
}

const ExportData: React.FC<IProps> = ({
  endpoint,
  generateFile,
  typeFile,
  text = '',
  checkedTitles,
  ...props
}) => {
  const onSuccess = ({ data }) => {
    generateFile(data, endpoint, checkedTitles, typeFile);
  };

  const { filter } = useFilter();
  const { sort } = useSort();
  const { source: sourcePath } = useActions();

  const filterById = filter[endpoint];
  const sortById = sort[endpoint];

  const rows = sourcePath['source'] === SOURCE['daily-rank'] ? DAILY_RANK_EXPORT_ROWS : '';

  const { refetch, isFetching } = useExportData(
    endpoint,
    typeFile,
    { ...filterById, ...sortById, rows },
    onSuccess
  );

  const getData = () => {
    window.gtag('event', 'click', { event_category: 'Interaction', event_label: 'Export Data' });
    refetch();
  };

  return (
    <button data-testid='export-btn' className='settings-menu-item basic-btn' onClick={getData}>
      <span className='settings-text'>{text}</span>
      {props.children}
      {isFetching && (
        <Spinner
          size='small'
          spinning={isFetching}
          indicator={
            <LoadingOutlined
              style={{ fontSize: 14, marginLeft: '10px' }}
              spin
            />
          }
        />
      )}
    </button>
  );
};

export default ExportData;