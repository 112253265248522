import React, { useEffect, useState, useCallback } from 'react';
import { Checkbox, Row, Col, Input, notification } from 'antd';
import {
  ALL_EXCLUDED,
  COMPETITORS,
  PODIUM_TITLES,
  SEND_TO_CRM,
  AUTHORS,
  PODIUMAUTHORS,
  AUTHOR_TAGS,
  SOURCE,
  SENT_TO_CRM_RR
} from '../../../core/constants';
import { useQueryClient } from 'react-query';
import { useUpdateTitle } from '../../../core/modules/book/hooks/useUpdateTitle.query';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { IBook } from '../../../section/Common/types/types';
import getTags from '../../../section/Common/utils/getTags';
import showNotification from '../../../section/Common/utils/showNotification';
import Modal from '../../../components/Modal';
import { useActions } from '../../../context/Actions.context';

const reasonForExclude = [
  'Author / Publisher',
  'Foreign Language',
  'Low Performer (manually)',
  'Lost Lead',
  'Subject Matter',
  'Audio already published'
];

interface IProps {
  book: IBook | any;
  modal: boolean;
  toggleModal: Function;
  id: string;
}

const excludeObject = (source, id, book) => {
  if (source === SOURCE.bookstat) {
    return { obj: { amazonasin: book.ASIN, title: book.Title }, obj_type: 'Title' };
  } else if (source === SOURCE['royal-road']) {
    return (id === AUTHORS || id === PODIUMAUTHORS) ?
      { obj: { author_id: book['Author Id'] }, obj_type: 'Author' } :
      { obj: { fiction_id: book['Fiction Id'], title: book.Title }, obj_type: 'Title' };
  }
};

const ExcludeTitles: React.FC<IProps> = ({ book, modal, toggleModal, id }) => {
  const { source, setActionLoading } = useActions();
  const exclude = excludeObject(source['source'], id, book);

  const { mutateAsync: mutateExclude } = useUpdateTitle(ALL_EXCLUDED);
  const { mutateAsync: mutatePodiumTitle } = useUpdateTitle(PODIUM_TITLES);
  const { mutateAsync: mutateCompetitors } = useUpdateTitle(COMPETITORS);
  const { mutateAsync: mutateExcludeAuthor } = useUpdateTitle(AUTHOR_TAGS);

  const [isCheckTags, setIsCheckTags] = useState({
    competitors: false,
    podiumTitles: false
  });
  const queryClient = useQueryClient();

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [input, setInput] = useState<string>('');
  const [isCheck, setIsCheck] = useState<boolean>(false);
  const [tags, setTags] = useState<CheckboxValueType[]>([]);

  const otherTabs = (tab, currentTags, mutateFN) => {
    const index = currentTags.indexOf(tab);

    if (index !== -1) {
      currentTags.splice(index, 1);

      const promise = mutateFN(exclude?.obj);

      showNotification(
        promise,
        `The ${exclude?.obj_type} was successfully excluded.`,
        `The ${exclude?.obj_type} has not been excluded.`
      );
    }
  };

  const onSend = () => {
    setActionLoading(true);
    const currentTags = [...tags];

    otherTabs('Podium Title', currentTags, mutatePodiumTitle);
    otherTabs('Competitors', currentTags, mutateCompetitors);

    let tag = getTags(currentTags);

    if (isCheck) {
      tag += `&tag=${input}`;
    }

    const promisePT = (id === AUTHORS || id === PODIUMAUTHORS) ?
      mutateExcludeAuthor({ ...exclude?.obj, tag: 'Excluded' }) :
      mutateExclude({ ...exclude?.obj, tag });

    showNotification(
      promisePT,
      `The ${exclude?.obj_type} was successfully excluded.`,
      `The ${exclude?.obj_type} has not been sent.`,
      book.Title
    );

    promisePT.then(() => {
      if (id === SEND_TO_CRM || id === SENT_TO_CRM_RR) {
        notification.info({
          description: 'Please delete the data from Hubspot.',
          message: ''
        });
      }
    });

    promisePT.then(() => queryClient.refetchQueries(`get-row-count${id}`));
    promisePT.then(() => setActionLoading(false));
    promisePT.then(() => queryClient.refetchQueries(`get-${id}`));
    toggleModal();
  };

  const handleCheckboxGroup = useCallback(
    value => {
      setTags(value);

      if (value.length) {
        if (value.includes(undefined) && !input) {
          setButtonDisabled(true);
          return;
        } else {
          setButtonDisabled(false);
          return;
        }
      }
      setButtonDisabled(true);
    },
    [input]
  );

  const handleInput = useCallback(({ target }) => {
    setInput(target.value);
  }, []);

  const handleReset = () => {
    setInput('');
    setIsCheck(false);
    setIsCheckTags({ competitors: false, podiumTitles: false });
    setButtonDisabled(true);
  };

  useEffect(() => {
    if (input && isCheck && tags?.length) {
      setButtonDisabled(false);
    } else if (isCheck && !input) {
      setButtonDisabled(true);
    }
  }, [isCheck, input]);

  const mutuallyExclusiveTags = (
    <>
      <Col span={24} key={'competitor'}>
        <Checkbox
          onChange={({ target }) => {
            const { checked } = target;
            setIsCheckTags({ ...isCheckTags, podiumTitles: checked });
          }}
          disabled={isCheckTags.competitors}
          value={'Competitors'}
        >
          Competitors
        </Checkbox>
      </Col>
      <Col span={24} key={'podium_title'}>
        <Checkbox
          onChange={({ target }) => {
            const { checked } = target;
            setIsCheckTags({ ...isCheckTags, competitors: checked });
          }}
          disabled={isCheckTags.podiumTitles}
          value={'Podium Title'}
        >
          Podium Title
        </Checkbox>
      </Col>
    </>
  );

  return (
    <Modal
      okText={`Exclude ${exclude?.obj_type}`}
      title={`Send ${exclude?.obj_type} to Exclusion List`}
      visible={modal}
      onOk={onSend}
      okButtonProps={{
        disabled: source['source'] === SOURCE['royal-road'] ?
          false : buttonDisabled
      }}
      onCancel={() => {
        toggleModal();
        handleReset();
      }}
    >
      {
        source['source'] === SOURCE['royal-road'] ? (
          <p>{`Sending ${exclude?.obj_type} to Exclusion List. Please confirm.`}</p>) : (<>
          <p>Select tag(s) for exclusion:</p>

          <Checkbox.Group className='modal-menu' onChange={handleCheckboxGroup}>
            <Row>
              {reasonForExclude.map((reason) => (
                <Col span={24} key={reason}>
                  <Checkbox value={reason}>{reason}</Checkbox>
                </Col>
              ))}
              {mutuallyExclusiveTags}
              <Col span={24} key={reasonForExclude.length + 1}>
                <Checkbox
                  data-testid='input-checkbox'
                  onChange={({ target }) => {
                    setIsCheck(target.checked);
                  } }
                >
                  <Input
                    disabled={!isCheck}
                    placeholder='Enter the tag'
                    className='modal-input'
                    onChange={handleInput} />
                </Checkbox>
              </Col>
            </Row>
          </Checkbox.Group></>)
      }
    </Modal>
  );
};

export default React.memo(ExcludeTitles);
