import React, { useEffect, useState } from 'react';
import { Drawer } from 'antd';
import { Resizable } from 'react-resizable';
import Popup from '../../components/Popup/Popup';
import { IBook, TColumns } from '../../section/Common/types/types';
import onContextMenu from './onContextMenu';
import onRowClick from './onRowClick';
import {
  AUTHORS,
  BOOKSTAT_DATA,
  PODIUMAUTHORS,
  PODIUM_SALES,
  PODIUM_TITLES,
  SOURCE
} from '../../core/constants';
import './styles.css';
import SendAll from '../Actions/SendAll';
import DeleteAllTitles from '../Actions/Delete/DeleteAllTitles';
import BlockSelectedAuthors from '../Actions/BlockAuthor/BlockSelectedAuthors';
import Table from '../../components/Table';
import { useUserPermit } from '../../context/User.context';
import { useActions } from '../../context/Actions.context';
import OverlayContent from '../Overlay/Overlay';
import { tabOptionsMapping } from './tabOptions';
import { useFilter } from '../../context/Filter.context';

interface IPopup {
  record: Object;
  visible: boolean;
  x: number;
  y: number;
}

interface IProps {
  fontSize: string;
  dataSource: IBook[] | undefined;
  deleteBooks?: Function;
  isFetching: boolean;
  deleteItems?: Array<string>;
  columns: TColumns[];
  id: string;
  refetchTable: Function;
}

const ResizeableTitle = ({
  onResize,
  onResizeStop,
  onResizeStart,
  width,
  ...restProps
}) => {
  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      onResizeStart={onResizeStart}
      onResizeStop={onResizeStop}
    >
      <th key='a' {...restProps} />
    </Resizable>
  );
};

const ResizeTable: React.FC<IProps> = ({
  fontSize,
  id,
  dataSource,
  deleteBooks,
  isFetching,
  deleteItems,
  columns,
  refetchTable
}) => {
  const [resize, setResize] = useState<any>({ isResizing: false, columns });
  const [visibleContextMenu, setVisibleContextMenu] = useState(false);
  const { edit_titles } = useUserPermit();
  const { source: sourcePath, isActionLoading } = useActions();
  const noMultiTitleOptionTabs = [BOOKSTAT_DATA, PODIUM_SALES, PODIUMAUTHORS, AUTHORS];
  const { filter } = useFilter();

  const [popup, setPopup] = useState<IPopup>({
    record: {},
    visible: false,
    x: 0,
    y: 0
  });

  const [openOverlay, setOpenOverlay] = useState({
    book: {},
    visible: false
  });

  useEffect(() => {
    setResize(({ isResizing }) => ({ isResizing, columns }));
  }, [columns]);

  const components = { header: { cell: ResizeableTitle } };

  const handleResize = index => (e, obj) => {
    const nextColumns = [...resize.columns];

    nextColumns[index] = {
      ...nextColumns[index],
      width: obj.size.width
    };

    setResize({ isResizing: false, columns: nextColumns });
  };

  const onResizeStart = () => {
    const col = resize.columns;
    setResize({ ...resize, columns: col });
  };

  const onResizeStop = () => {
    const col = resize.columns;
    setResize({ ...resize, columns: col });
  };

  const columnsT = resize?.columns.map((col, index) => ({
    ...col,
    onHeaderCell: column => ({
      width: column.width,
      onResize: handleResize(index),
      onResizeStart,
      onResizeStop
    })
  }));

  const handleRowClick = (book) => {
    setOpenOverlay({ visible: true, book });
  };

  const handleCancel = () => {
    setOpenOverlay({ visible: false, book: {} });
  };

  useEffect(() => {
    if (!sourcePath['source']?.includes('daily-rank')) {
      const th = document.createElement('th') as HTMLElement;
      th.classList.add('ant-table-cell', 'cell-fix', 'ant-table-cell-fix-right');
      const tr = document.querySelector('tr');
      tr?.appendChild(th);
    }

    if (dataSource && dataSource.length === 1 && window.location.search) {
      handleRowClick(dataSource[0]);
    }

    return () => {
      handleCancel();
    };
  }, []);

  useEffect(() => {
    if (isActionLoading) {
      handleCancel();
    }
  }, [isActionLoading]);

  const onDoubleClick = record => () => {
    const selectItems = onRowClick(record, deleteItems);

    if (deleteBooks) {
      deleteBooks([...selectItems]);
    }
  };

  const [openConfirm, setOpenConfirm] = useState({ type: '' });
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openBlockAuthorsModal, setOpenBlockAuthorsModal] = useState<boolean>(false);
  const [selectedTitles, setSelectedTitles] = useState<any>([]);

  const tabOptions = tabOptionsMapping({
    selectedTitles,
    setOpenConfirm,
    setOpenDeleteModal,
    setOpenBlockAuthorsModal
  });

  const rowSelection: any = {
    onChange: (selectedRowKeys: []) => {
      setSelectedTitles(selectedRowKeys);
    },
    selections: tabOptions[id] || []
  };

  const handleClose = () => {
    setOpenConfirm({ type: '' });
  };

  const handleReset = () => {
    setSelectedTitles([]);
  };

  const selections = sourcePath['source'] !== SOURCE['daily-rank'] &&
    !noMultiTitleOptionTabs.includes(id) && edit_titles && {
    type: 'checkbox',
    selectedRowKeys: selectedTitles,
    ...rowSelection
  };

  return (
    <>
      <DeleteAllTitles
        book_ids={selectedTitles}
        open={openDeleteModal}
        onReset={() => handleReset()}
        onClose={() => setOpenDeleteModal(false)}
        id={id}
      />
      <BlockSelectedAuthors
        selected_ids={selectedTitles}
        open={openBlockAuthorsModal}
        onReset={() => handleReset()}
        onClose={() => setOpenBlockAuthorsModal(false)}
        id={id}
      />
      <SendAll
        book_ids={selectedTitles}
        open={Boolean(openConfirm?.type)}
        name={openConfirm?.type}
        onClose={handleClose}
        onReset={handleReset}
        id={id}
      />

      <Table
        pagination={false}
        components={components}
        columns={columnsT}
        dataSource={dataSource}
        style={{ fontSize }}
        bordered
        rowSelection={selections}
        loading={isFetching}
        onRow={record => {
          const rowClassName = sourcePath['source'] !== SOURCE['daily-rank'] ? 'cursor-pointer' : '';

          if (id === PODIUM_TITLES) {
            return {
              onDoubleClick: onDoubleClick(record),
              onContextMenu: (e: React.MouseEvent<HTMLElement, MouseEvent>) =>
                onContextMenu(record, e, {
                  visibleContextMenu,
                  setPopup,
                  setVisibleContextMenu
                }),
              onClick: () => handleRowClick(record),
              className: rowClassName
            };
          } else if (id === BOOKSTAT_DATA || id === PODIUM_SALES || id === AUTHORS || id === PODIUMAUTHORS) {
            return {};
          }

          return {
            onDoubleClick: onDoubleClick(record),
            onClick: () => handleRowClick(record),
            className: rowClassName
          };
        }}
      />
      <Popup {...popup} />
      {sourcePath['source'] !== SOURCE['daily-rank'] &&
      <Drawer
        visible={openOverlay.visible && !filter[id]?.createDeal}
        onClose={handleCancel}
        destroyOnClose
        placement='right'
        width={'50vw'}
        className='custom-drawer'
      >
        <OverlayContent
          tabId={id}
          book={openOverlay.book}
          refetchTable={refetchTable}
        />
      </Drawer>}
    </>
  );
};

export default React.memo(ResizeTable);