import {
  ALL_EXCLUDED,
  ALL_NEW_TITLE,
  BOOKSTAT_DATA,
  COMPETITORS,
  MANUALLY_ADDED,
  PENDING_EXCLUSION,
  PODIUM_SALES,
  PODIUM_TITLES,
  SEND_TO_CRM,
  WATCHLIST,
  AUTHORS,
  RISING_STARS,
  POPULAR,
  TRENDING,
  ALL_TITLES_RR,
  ALL_EXCLUDED_RR,
  SENT_TO_CRM_RR,
  WATCHLIST_RR,
  DAILY_RANK_EBOOKS,
  DAILY_RANK_AUDIO_BOOKS,
  DAILY_RANK_CATALOGUE,
  PODIUMAUTHORS,
  PENDING_EXCLUSION_RR,
  PODIUM_TITLES_RR,
  HOT_TITLES
} from '../../core/constants';

export const REFETCHDELAY = 250;

export const RefetchOptions = { cancelRefetch: true };

export const BASEFONTSIZE = '13px';

const fontSize = ['8px', '10px', '12px', '13px', '14px', '16px'];

export const FONTSIZE = fontSize.map(size => ({ label: size, value: size }));

export const PAGESIZE = 100;

interface IColumnsHeadin {
  [key: string]: string[];
}

const commonColumns = [
  'Added',
  'Days Tracked',
  'Title',
  'Author',
  'ASIN',
  'Genre',
  'Podium Hit Count',
  'Projected Sales',
  'Average Projected Sales',
  'Max Projected Sales',
  'Num. of Pages',
  'ISBN',
  'Publisher Type',
  'Series',
  'Date Pub',
  'Days On Sale',
  'Amzn Rating',
  'Amzn Review',
  'eBooks Sold',
  'Confidence',
  'Average Confidence',
  'Max Confidence',
  'Price',
  // 'Kindle Sales Rank',
  'Kindle Unlimited',
  'Tags'
];

const baseColumns = [
  'Added',
  'Days Tracked',
  'Title',
  'Author',
  'ASIN',
  'Genre',
  'Podium Hit Count',
  'Projected Sales',
  'Num. of Pages',
  'ISBN',
  'Publisher Type',
  'Series',
  'Date Pub',
  'Days On Sale',
  'Amzn Rating',
  'Amzn Review',
  'eBooks Sold',
  'Confidence'
];

const royalRoadCommonColumns = [
  'Added',
  'Title',
  'Author',
  'Patreon',
  'External Links',
  'First Published Date',
  'Genre',
  'Overall Score',
  'Grammar Score',
  'Views',
  'Followers',
  'Favorites',
  'Ratings',
  'Pages',
  'Type',
  'Warnings',
  'Status'
];

const royalRoadAuthorColumns = [
  'Added',
  'Author',
  'Fictions',
  'Words',
  'Reviews',
  'Author Ratings',
  'Followers',
  'Favorites',
  'Reputation Level',
  'Achievements',
  'Patreon',
  'Discord',
  'Twitter'
];

const dailyRankColumns = [
  'More',
  'Series',
  'Author',
  'ASIN',
  'Release Date',
  'Highest Rank',
  'Highest Rank Date',
  'Book #',
  'Kindle Unlimited?',
  'Tier',
  'Last Updated'
];

const bookstatCommonColumns = [
  'Added', 'Days Tracked', 'Title',
  'Author', 'ASIN', 'Genre',
  'Podium Hit Count', 'Projected Sales', 'Average Projected Sales',
  'Max Projected Sales', 'Num. of Pages', 'Avg. Also Bought Bestseller Rank',
  'ISBN', 'Agent', 'Agency', 'Publisher Type',
  'Series', 'Date Pub', 'Days On Sale',
  'Amzn Rating', 'Amzn Review', 'eBooks Sold',
  'Confidence', 'Average Confidence',
  'Max Confidence', 'Price',
  'Kindle Unlimited', 'Tags'
];

export const COLUMNSHEADING: IColumnsHeadin = {
  [ALL_NEW_TITLE]: [...bookstatCommonColumns],
  [HOT_TITLES]: [...bookstatCommonColumns],
  [MANUALLY_ADDED]: [...bookstatCommonColumns.filter(col => col !== 'Avg. Also Bought Bestseller Rank')],
  [ALL_EXCLUDED]: [...commonColumns],
  [BOOKSTAT_DATA]: [...baseColumns],
  [COMPETITORS]: [...commonColumns],
  [PENDING_EXCLUSION]: [...commonColumns],
  [PODIUM_SALES]: [
    'Added',
    'Days Tracked',
    'Title',
    'Author',
    'First 12 months sales (units)',
    'Trailing 12 months sales (units)',
    'Lifetime sales (units)',
    'Lifetime sales (dollars)',
    'Audible Product ID',
    'ASIN',
    'Podium Hit Count',
    'Projected Sales',
    'Num. of Pages',
    'ISBN',
    'Series',
    'Date Pub',
    'Days On Sale',
    'Amzn Rating',
    'Amzn Review',
    'eBooks Sold',
    'Confidence'
  ],
  [PODIUM_TITLES]: [
    'Added',
    'Days Tracked',
    'Title',
    'Author',
    'ASIN',
    'Genre',
    'Podium Hit Count',
    'Projected Sales',
    'Average Projected Sales',
    'Max Projected Sales',
    'Num. of Pages',
    'Avg. Also Bought Bestseller Rank',
    'ISBN',
    'Agent',
    'Agency',
    'Publisher Type',
    'Series',
    'Date Pub',
    'Days On Sale',
    'Amzn Rating',
    'Amzn Review',
    'eBooks Sold',
    'Confidence',
    'Average Confidence',
    'Max Confidence',
    'Price',
    'Kindle Unlimited',
    'First 12 months sales (units)',
    'Trailing 12 months sales (units)',
    'Lifetime sales (units)',
    'Lifetime sales (dollars)',
    'Tags'
  ],
  [SEND_TO_CRM]: [
    'Sent to CRM on date',
    'Added',
    'Days Tracked',
    'Title',
    'Author',
    'ASIN',
    'Genre',
    'Podium Hit Count',
    'Projected Sales',
    'Average Projected Sales',
    'Max Projected Sales',
    'Num. of Pages',
    'Avg. Also Bought Bestseller Rank',
    'ISBN',
    'Publisher Type',
    'Series',
    'Date Pub',
    'Days On Sale',
    'Amzn Rating',
    'Amzn Review',
    'eBooks Sold',
    'Confidence',
    'Average Confidence',
    'Max Confidence',
    'Price',
    'Kindle Unlimited',
    'Assignee',
    'Tier',
    'Priority',
    'Tags'
  ],
  [WATCHLIST]: [
    'Added to Watchlist',
    'Added',
    'Days Tracked',
    'Title',
    'Author',
    'ASIN',
    'Genre',
    'Podium Hit Count',
    'Projected Sales',
    'Average Projected Sales',
    'Max Projected Sales',
    'Num. of Pages',
    'Avg. Also Bought Bestseller Rank',
    'ISBN',
    'Agent',
    'Agency',
    'Publisher Type',
    'Series',
    'Date Pub',
    'Days On Sale',
    'Amzn Rating',
    'Amzn Review',
    'eBooks Sold',
    'Confidence',
    'Average Confidence',
    'Max Confidence',
    'Price',
    'Kindle Unlimited',
    'Tags'
  ],
  [AUTHORS]: [...royalRoadAuthorColumns],
  [PODIUMAUTHORS]: [...royalRoadAuthorColumns],
  [ALL_TITLES_RR]: [...royalRoadCommonColumns],
  [POPULAR]: [...royalRoadCommonColumns],
  [RISING_STARS]: [...royalRoadCommonColumns],
  [TRENDING]: [...royalRoadCommonColumns],
  [WATCHLIST_RR]: [...royalRoadCommonColumns],
  [SENT_TO_CRM_RR]: [...royalRoadCommonColumns],
  [PENDING_EXCLUSION_RR]: [...royalRoadCommonColumns],
  [PODIUM_TITLES_RR]: [...royalRoadCommonColumns],
  [ALL_EXCLUDED_RR]: [...royalRoadCommonColumns],
  [DAILY_RANK_EBOOKS]: [...dailyRankColumns],
  [DAILY_RANK_AUDIO_BOOKS]: [...dailyRankColumns, 'Narrator'],
  [DAILY_RANK_CATALOGUE]: [...dailyRankColumns]
};

export const FILETYPE = {
  csv: 'data:text/csv;charset=utf-8',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
};

export const FILEEXTENSION = {
  csv: '.csv',
  xlsx: '.xlsx'
};

export const EXPORTTYPE = {
  csv: 'csv',
  xlsx: 'xlsx'
};

export const COLUMNINFO = {
  'Added': 'Date when the title was added to Brenda.',
  'Days Tracked': 'Number of days since the title was added.',
  'Projected Sales': 'Predicted sales value of the title for the next 50 days.',
  'Average Projected Sales': 'Average of the total projected sales to date.',
  'Max Projected Sales': 'Maximum of the total projected sales to date.',
  'Confidence': 'Probability of the title crossing the threshold value.',
  'Average Confidence': `Average probability of the title crossing the threshold value
    from the day it was added to Brenda.`,
  'Max Confidence': `Maximum probability of the title crossing the threshold value
    from the day it was added to Brenda.`
};

export const ExcludeParamKeys = ['option', 'tier', 'priority', 'createDeal', 'assignee'];
