import React from 'react';
import { Form, Formik } from 'formik';
import Modal from '../../../components/Modal';
import showNotification from '../../../section/Common/utils/showNotification';
import { Title } from '../../../components/styles';
import { useQueryClient } from 'react-query';
import {
  AUTHORS,
  BLOCKAUTHOR,
  PODIUMAUTHORS,
  SOURCE
} from '../../../core/constants';
import { useActions } from '../../../context/Actions.context';
import { useUpdateTitle } from '../../../core/modules/book/hooks/useUpdateTitle.query';
import { notification } from 'antd';

interface IAuthorProps {
  ASIN: string;
  Author?: string;
}

interface IProps {
  book: IAuthorProps;
  closeModal: Function;
  modal: boolean;
  id: string;
}

const blockAuthorObject = (source, id, book) => {
  if (source === SOURCE.bookstat) {
    return {
      obj: { amazonasin: book.ASIN },
      obj_type: 'Title'
    };
  } else if (source === SOURCE['royal-road']) {
    return id === AUTHORS || id === PODIUMAUTHORS
      ? { obj: { author_id: book['Author Id'] }, obj_type: 'Author' }
      : {
        obj: { fiction_id: book['Fiction Id'] },
        obj_type: 'Title'
      };
  }
};

const BlockAuthorTitle: React.FC<IProps> = ({
  id,
  book,
  modal,
  closeModal
}) => {
  const { ASIN, Author } = book;
  const { source, isActionLoading, setActionLoading } = useActions();
  const blockAuthorObj = blockAuthorObject(source['source'], id, book);
  const { mutateAsync } = useUpdateTitle(BLOCKAUTHOR);
  const queryClient = useQueryClient();

  const onBlock = (_values, cb) => {
    setActionLoading(true);

    try {
      const promise = mutateAsync({ ...blockAuthorObj?.obj });

      cb.resetForm();
      showNotification(
        promise,
        'The Author was successfully blocked.',
        'The Author has not been blocked.',
        Author
      );
      promise.then(() => queryClient.refetchQueries(`get-row-count${id}`));
      promise.then(() => queryClient.refetchQueries(`get-${id}`));
      promise.finally(() => setActionLoading(false));
    } catch (e) {
      closeModal();
      cb.resetForm();
      notification?.error({
        description: 'Please try again.',
        message: 'Failed to block author'
      });
      setActionLoading(false);
    } finally {
      closeModal();
      cb.resetForm();
    }
  };

  return (
    <Formik
      initialValues={{
        amazonasin: ASIN,
        fiction_id: book['Fiction Id']
      }}
      enableReinitialize={true}
      onSubmit={onBlock}
    >
      {({ handleSubmit, resetForm }) => (
        <Form>
          <Modal
            okText='Block Author'
            title={`Block titles by ${book['Author']}`}
            visible={modal}
            onOk={handleSubmit}
            onCancel={() => {
              resetForm();
              closeModal();
            }}
            confirmLoading={isActionLoading}
          >
            <Title>
              <p>You are about to <strong>permanently block</strong> this author.</p>
              <br />
              <strong>Please note: </strong>
              <p>
                Blocking these authors will exclude all of their titles, including the selected ones, from Brenda.
              </p>
            </Title>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default React.memo(BlockAuthorTitle);
