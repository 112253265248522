import React, { useContext, useMemo, useState } from 'react';
import { TFilterItem, TFilters } from '../section/Common/types/types';

type TCRMFilter = {
  createDeal?: boolean;
  priority?: string;
  tier?: number;
  assignee?: string;
};

export const baseFilter: TFilterItem = {
  amazonasin: [],
  author: [],
  genres: [],
  search: '',
  title: [],
  tag: [],
  seriestitle: [],
  not_tag: [],
  days_tracked: undefined,
  status_tag: undefined,
  type_tag: undefined,
  reputation: [],
  author_tags: [],
  author_name: [],
  fiction_tags: [],
  fiction_name: [],
  warning_tags: [],
  not_warning_tags: [],
  genre_categories: [],
  date_range: undefined,
  late_bloomer: undefined,
  series: []
};

export const CRMFilter: TCRMFilter = {
  createDeal: undefined,
  priority: undefined,
  tier: undefined,
  assignee: undefined
};

export const defaultFilter: TFilters = {
  allnewtitles: { ...baseFilter, ...CRMFilter },
  hotTitles: { ...baseFilter, ...CRMFilter },
  allexcluded: { ...baseFilter },
  manuallyadded: { ...baseFilter },
  bookstatdata: { ...baseFilter },
  competitors: { ...baseFilter },
  pendingexclusion: { ...baseFilter },
  podiumsales: { ...baseFilter },
  podiumtitles: { ...baseFilter },
  sendtocrm: { ...baseFilter },
  watchlist: { ...baseFilter, ...CRMFilter },
  popular: { ...baseFilter },
  trending: { ...baseFilter },
  risingStars: { ...baseFilter },
  authors: { ...baseFilter },
  podiumAuthors: { ...baseFilter },
  alltitlesRR: { ...baseFilter },
  pendingExclusionRR: { ...baseFilter },
  excludedRR: { ...baseFilter },
  watchlistRR: { ...baseFilter },
  podiumtitlesRR: { ...baseFilter },
  senttocrmRR: { ...baseFilter },
  dailyRankEbooks: { ...baseFilter },
  dailyRankAudioBooks: { ...baseFilter },
  dailyRankCatalogue: { ...baseFilter }
};

const FilterContext = React.createContext({
  filter: defaultFilter,
  setFilter: values => console.log(values)
});

export const FilterProvider = ({ children, filter }) => {
  const [currentFilter, setCurrentFilter] = useState(filter || defaultFilter);

  const saveFilter = values => {
    setCurrentFilter(values);
    return null;
  };

  const value = useMemo(() => ({
    filter: currentFilter,
    setFilter: saveFilter
  }), [currentFilter]);

  return (
    <FilterContext.Provider value={value}>
      {children}
    </FilterContext.Provider>
  );
};

export const FilterConsumer = FilterContext.Consumer;

export const useFilter = () => {
  const context = useContext(FilterContext);

  return context;
};

export default FilterContext;
