import React from 'react';
import { notification } from 'antd';
import { useQueryClient } from 'react-query';
import { useServices } from '../../../core/services/useServices';
import Endpoints, {
  BLOCKAUTHOR,
  SOURCE
} from '../../../core/constants/endpoints';
import { Title } from '../../../components/styles';
import { Form, Formik } from 'formik';
import Modal from '../../../components/Modal';
import { useActions } from '../../../context/Actions.context';

interface IProps {
  selected_ids: [];
  onClose: Function;
  onReset: Function;
  open: boolean;
  id: string;
}

const BlockSelectedAuthors: React.FC<IProps> = ({
  selected_ids,
  open,
  onClose,
  onReset,
  id
}) => {
  const {
    book: { updateTitle }
  } = useServices();
  const {
    source: sourcePath,
    isActionLoading,
    setActionLoading
  } = useActions();

  const queryClient = useQueryClient();
  const sourceTab = window.location.pathname.split('/')[2];

  const onBlockAll = async (_values, cb) => {
    setActionLoading(true);

    try {
      const data: { [key: string]: any } = {};

      if (sourcePath['source'] === SOURCE.bookstat) {
        data.amazonasin = selected_ids;
      } else if (sourcePath['source'] === SOURCE['royal-road']) {
        data.fiction_id = selected_ids;
      }

      const response = await updateTitle(
        {
          source: sourcePath['source'],
          endpoint: Endpoints[BLOCKAUTHOR],
          sourceTab,
          action: BLOCKAUTHOR
        },
        data
      );

      onClose();
      onReset();
      cb.resetForm();

      if (response?.status === 200) {
        notification?.success({
          description: '',
          message: 'The Authors were successfully blocked.'
        });
        queryClient.refetchQueries(`get-row-count${id}`);
        queryClient.refetchQueries(`get-${id}`);
        return;
      }
    } catch (e) {
      onClose();
      onReset();
      cb.resetForm();
      notification?.error({
        description: 'Please try again.',
        message: 'Failed to block authors.'
      });
    } finally {
      setActionLoading(false);
    }
  };

  return (
    <Formik
      initialValues={
        sourcePath['source'] === SOURCE.bookstat
          ? {
            amazonasin: selected_ids
          }
          : { fiction_id: selected_ids }
      }
      onSubmit={onBlockAll}
    >
      {({ handleSubmit, resetForm }) => (
        <Form>
          <Modal
            okText='Block Authors'
            title='Block Selected Author(s)'
            visible={open}
            onOk={handleSubmit}
            onCancel={() => {
              resetForm();
              onClose();
            }}
            confirmLoading={isActionLoading}
          >
            <Title>
              <p>You are about to <strong>permanently block</strong> {selected_ids?.length} author(s).</p>
              <br />
              <strong>Please note: </strong>
              <p>
                Blocking these authors will exclude all of their titles, including the selected ones, from Brenda.
              </p>
            </Title>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default BlockSelectedAuthors;
