import { useCallback, useEffect, useMemo, useState } from 'react';
import { RISING_STARS, ROYAL_ROAD_TITLE } from '../../../core/constants';
import { useBooksList, useDataRowCount } from '../../../core/modules/book/hooks';
import { BlockAuthor, Delete, Exclude, ToCRM, Watch } from '../../../composed-components/Actions';
import { BASEFONTSIZE, COLUMNSHEADING, PAGESIZE, REFETCHDELAY, RefetchOptions } from '../constants';
import { FilterForm, Settings, Sorting, SpinnerTabs } from '../../../composed-components';
import ResizeTable from '../../../composed-components/Resize';
import { EFontSize, TColumns } from '../types/types';
import Pagination from '../../../components/Pagination';
import { useFilter } from '../../../context/Filter.context';
import { useSort } from '../../../context/Sort.context';
import { useUserPermit } from '../../../context/User.context';
import { useActions } from '../../../context/Actions.context';
import Legend from '../../../composed-components/Legend';
import { TableFooter } from '../../../components/Table';
import getAllColumns from '../columns/getAllColumns';
import Endpoints from '../../../core/constants/endpoints';
import getDataSource from '../utils/getDataSource';

const RisingStars = () => {
  const [fontSize, setFontSize] = useState<keyof typeof EFontSize>(BASEFONTSIZE);
  const [page, setPage] = useState<number>(1);
  const [deleteItems, setDeleteItems] = useState<string[]>([]);

  const { edit_titles, move_titles_hubspot } = useUserPermit();
  const { isActionLoading, setActionLoading, isRefreshed } = useActions();
  const { filter } = useFilter();
  const { sort } = useSort();

  const changePage = p => setPage(p);
  const deleteBooks = book => setDeleteItems(book);
  const changeFontSize = size => setFontSize(size);

  const { data = [], isLoading, isFetching, refetch } = useBooksList(
    RISING_STARS, {
      page,
      ...filter[RISING_STARS],
      ...sort[RISING_STARS]
    });

  const { count, refetch: refetchRowCount } = useDataRowCount(RISING_STARS, { ...filter[RISING_STARS] });

  const refetchTable = () => {
    setTimeout(() => {
      refetch();
      refetchRowCount();
      setActionLoading(false);
    }, REFETCHDELAY);
  };

  const sortComponent = useCallback(
    (title: string) => (
      <Sorting id={RISING_STARS} title={title} changePage={changePage} />
    ),
    []
  );

  const thead = useMemo(() => {
    const allColumns = getAllColumns(sortComponent);
    const head = COLUMNSHEADING[RISING_STARS].map(title => {
      if (title === 'Title') {
        return {
          ...allColumns[title],
          render (Title: string, _book) {
            if (Title && _book['Fiction Id']) {
              return {
                children: <div><a
                  className='table-cell-asin'
                  href={`${Endpoints[ROYAL_ROAD_TITLE]}${_book['Fiction Id']}`}
                  rel='noreferrer'
                  target='_blank'
                >
                  {Title}
                </a></div>
              };
            }
          }
        };
      }

      return allColumns[title];
    });

    if (edit_titles) {
      head.push({
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        className: 'show td-actions',
        fixed: 'right',
        width: 90,
        render: (_, book) => (
          <div className='actions'>
            <Delete book={book} id={RISING_STARS} />
            <BlockAuthor book={book} id={RISING_STARS} />
            <Exclude book={book} id={RISING_STARS} />
            <Watch book={book} refetchTable={refetchTable} id={RISING_STARS} />
            {move_titles_hubspot && <ToCRM book={book} id={RISING_STARS} />}
          </div>
        )
      });
    }

    return head;
  }, [edit_titles, move_titles_hubspot, sortComponent]);

  const [columns, setColumns] = useState<TColumns[]>(thead);
  const dataSource = getDataSource(data, 'All Titles', false);

  useEffect(() => {
    if (!isRefreshed) {
      refetch(RefetchOptions);
      refetchRowCount(RefetchOptions);
    }
  }, [page, filter[RISING_STARS], sort[RISING_STARS]]);

  return (
    <>
      <div className={'wrapper-controller'}>
        <FilterForm id={RISING_STARS} page={page} changePage={changePage} />
        <Settings
          thead={thead}
          refetchTable={refetchTable}
          id={RISING_STARS}
          changeFontSize={changeFontSize}
          setColumns={setColumns}
          deleteBooks={deleteBooks}
          deleteItems={deleteItems}
        />
      </div>
      {!isLoading ? (
        <>
          <ResizeTable
            fontSize={fontSize}
            id={RISING_STARS}
            dataSource={dataSource}
            deleteItems={deleteItems}
            isFetching={isFetching || isActionLoading}
            deleteBooks={deleteBooks}
            columns={columns}
            refetchTable={refetchTable}
          />
          <TableFooter>
            <Legend map={['crm', 'podium', 'price']} />
            <Pagination
              total={count}
              current={page}
              onChange={setPage}
              pageSize={PAGESIZE}
            />
          </TableFooter>
        </>
      ) : (
        <SpinnerTabs isLoading={isLoading} />
      )}
    </>
  );
};

export default RisingStars;