import React, { useEffect } from 'react';
import { Col, Row, Card, Popover, message } from 'antd';
import { SelectOutlined, PaperClipOutlined } from '@ant-design/icons';
import { BoxedSetTag, Hint, LateBloomerTag, RisingStar } from '../../components/FormItem';
import { OverlayBookstatDataList, OverlayRoyalRoadDataList } from '../../core/constants/global-constants';
import { AMAZON, TAB_NAME } from '../../core/constants';
import Endpoints, { ROYAL_ROAD_TITLE } from '../../core/constants/endpoints';
import AlsoBought from '../AlsoBought';
import styled from 'styled-components';
import './styles.css';
import { useGetBookDetails } from '../../core/modules/book/hooks/useGetBookDetails';
import PredictionsGraph from './PredictionsGraph';
import OverlayActions from './OverlayActions';

const { Meta } = Card;

interface IOverlayContentProps {
  book: object;
  tabId: string;
  refetchTable: Function;
}

const Dot = styled.span`
    width: 15px;
    height: 15px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 8px;
    border: 2px solid white;
    background-color: ${({ color }) => color};
`;

const tagColors = ['#5b89c2', '#c95c57', '#8108b3', '#008028', '#1dc6cc'];

const OverlayContent: React.FC<IOverlayContentProps> = ({ book, tabId, refetchTable }) => {
  const { data, isLoading, refetchBookDetails } = useGetBookDetails(
    book['ASIN']
  );

  useEffect(() => {
    if (book['ASIN']) {
      refetchBookDetails();
    }
  }, [book]);

  const [messageApi, contextHolder] = message.useMessage();
  book['Current Tab'] = TAB_NAME[tabId];
  const filteredKeys = book['ASIN']
    ? OverlayBookstatDataList.filter((key) => Object.prototype.hasOwnProperty.call(book, key))
    : OverlayRoyalRoadDataList.filter((key) => Object.prototype.hasOwnProperty.call(book, key));

  const zoomedCoverImage = (
    <div>
      <img
        alt={book['Title']}
        src={book['largecoverimageurl']}
        className='overlay-zoomed-img'
      />
    </div>
  );

  const handleCopyUrl = () => {
    const book_id = book['ASIN'] ? `amazonasin=${book['ASIN']}` : `fiction_id=${book['Fiction Id']}`;
    let bookUrl = window.location.href;
    const urlWithoutSearch = bookUrl.split('?')[0];
    bookUrl = `${urlWithoutSearch}?${book_id}`;
    message.config({ top: 10, maxCount: 3, rtl: false, duration: 1 });
    navigator.clipboard.writeText(bookUrl).then(() => {
      messageApi.open({
        type: 'success',
        content: 'Copied link to clipboard.',
        duration: 1
      });
    });
    message.destroy();
  };

  const customColumn = (key: string) => {
    switch (key) {
    case 'ASIN' :
      return (
        <div>
          <a
            className='table-cell-asin'
            href={`${Endpoints[AMAZON]}${book[key]}`}
            rel='noreferrer'
            target='_blank'
          >
            {book[key]} <SelectOutlined />
          </a>
        </div>
      );
    case 'Fiction Id' :
      return (
        <div>
          <a
            className='table-cell-asin'
            href={`${Endpoints[ROYAL_ROAD_TITLE]}${book[key]}`}
            rel='noreferrer'
            target='_blank'
          >
            {book[key]} <SelectOutlined />
          </a>
        </div>
      );
    case 'Podium Hit Count' :
      return (
        <div className='table-cell-asin'>
          <AlsoBought
            podiumHitCount={book[key] === null ? -1 : book[key]}
            asin={book['ASIN']}
          />
        </div>
      );
    case 'Patreon' :
      return (
        <div
          style={{ maxWidth: '20vw' }}><a
            className='table-cell-asin'
            href={`${book[key]}`}
            rel='noreferrer'
            target='_blank'
          >
            {book[key]}
          </a></div>
      );
    default :
      return (
        book[key]
      );
    }
  };

  return (
    <>
      {contextHolder}
      <Row className='card-row'>
        <Col span={24}>
          <Card className='overlay-card'>
            <Meta
              className='overlay-meta'
              avatar={
                <Popover content={zoomedCoverImage} placement='leftBottom' trigger={'hover'}>
                  <img src={book['largecoverimageurl']}
                    alt={''}
                    className='overlay-img'
                  />
                </Popover>
              }
              title={
                <div className='overlay-text-wrap'>
                  <div className='overlay-author-container'>
                    <div className='overlay-author'>
                      {book['CRM Author Id'] &&
                        <Popover content={<p>Author Sent To Hubspot</p>}>
                          <Dot color={'rgba(158,255,201,0.42)'}/>
                        </Popover>}
                      {book['Author']}
                    </div>
                    <div className='header-notification'>
                      <PaperClipOutlined
                        data-testid='copy-btn'
                        className='copy-icon'
                        onClick={handleCopyUrl}/>
                    </div>
                  </div>
                  <div className='overlay-title-wrap'>
                    {book['Title']}
                    <div>
                      {book['series_has_audio'] ? (
                        <Hint hint='A title in this series has audio' color={'#00B27B'} />
                      ) : null}
                      {book['late_bloomer'] ?
                        (<LateBloomerTag hint='Late Bloomer' color={'red'}/>) : null}
                      {book['is_boxset'] ?
                        (<BoxedSetTag hint='This title is a boxed set'/>) : null}
                      {book['is_risingstar'] ? (
                        <RisingStar hint='This title has been a Rising Star' color={'#F6BE00'}/>
                      ) : null}
                    </div>
                  </div>
                </div>
              }
              description={
                <div className='overlay-description-wrap'>
                  {book['Series']}
                </div>
              }
            />
          </Card>
        </Col>
      </Row>
      <Row>
        <Col span={24} className='overlay-actions-row'>
          <OverlayActions book={book} TAB_ID={tabId} refetchTable={refetchTable} />
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ display: 'flex' }} className='col-tag'>
          {book['Primary Tags']?.split(',').map((tag, index) => {
            const colorIndex = index % tagColors.length;

            return (
              <div className='overlay-tags' key={tag} style={{ backgroundColor: tagColors[colorIndex] }}>
                {tag.trim()}
              </div>
            );
          })}
          {book['Secondary Tags']?.split(',').map((tag, index) => {
            const colorIndex = (index + (book['Primary Tags']?.split(',')?.length || 0)) % tagColors.length;

            return (
              <div className='overlay-tags' key={tag} style={{ backgroundColor: tagColors[colorIndex] }}>
                {tag.trim()}
              </div>
            );
          })}
        </Col>
      </Row>
      <div className='overlay-table'>
        {filteredKeys.map((key, index) => (
          <Row
            className={index % 2 === 0 ? 'overlay-even-row' : 'overlay-odd-row'}
            key={key}
          >
            <Col span={12} className='overlay-column'>
              {key}
            </Col>
            <Col span={12} className='overlay-column'>
              {customColumn(key)}
            </Col>
          </Row>
        ))}
      </div>
      {book['ASIN'] && <div>
        <PredictionsGraph
          data={data?.data || []}
          isLoading={isLoading}
        />
      </div>}
    </>
  );
};

export default OverlayContent;