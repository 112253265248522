import { useQuery } from 'react-query';
import { useServices } from '../../../services/useServices';
import { IUserData } from '../user.dto';

const useUser = () => {
  const {
    user: { getUser }
  } = useServices();

  const res = useQuery<{ data: IUserData }, Error>('user-details', () => getUser(), {
    refetchInterval: false,
    cacheTime: 0
  });

  return { data: res?.data?.data?.[0], isLoading: res?.isLoading };
};

export default useUser;