export const getDate = () => {
  const date = Date().split(' ');

  return date[0] + date[1] + date[2] + date[3];
};

export const formatDuration = (seconds: number): string => {
  if (seconds <= 0) {
    return '-';
  }

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  const formattedHours = hours > 0 ? `${hours}h ` : '';
  const formattedMinutes = minutes > 0 ? `${minutes}m ` : '';

  return formattedHours + formattedMinutes;
};

export const getPercentDuration = (duration: string, total: string) => {
  const durationInt = parseInt(duration, 10);
  const totalInt = parseInt(total, 10);

  if (isNaN(durationInt) || isNaN(totalInt) || totalInt === 0) {
    return 0;
  }

  return Math.floor((durationInt / totalInt) * 100);
};